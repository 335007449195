import { useEffect, useState } from "react";
import ReactLoading from "react-loading";
// import wallet from "../../assets/wallet.png";
import styles from "./quickSell.module.scss";
import { ErrorMessage } from "../career/Career";
// import solana from "../../assets/solana.png";
// import coinbase from "../../assets/coinbase.png";
import ulit from "../../assets/ulit.png";
import dasaLogo from "../../assets/dasaLogo.png";
// import downloadIcon from "../../assets/download.svg";
import backIcon from "../../assets/back.svg";
import Login from "../auth/Login";
import Signup from "../auth/Signup";
import HelpCentre from "../helpCenter/HelpCentre";
import WalletConnect from "../walletConnect/WalletConnect";
// import axios from "axios";
// import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { ButtonMain } from "../StyledComponent";
import { Input, Select } from "../../pages/getLoan/GetLoan";
import Modal from "react-modal";
import closeIcon from "../../assets/close.svg";
import Verify from "../verify/Verify";
import checkIcon from "../../assets/check.svg";
import copy from "../../assets/copy.svg";
import arrowDown from "../../assets/arrowDown.svg";
import otcLogo from "../../assets/otcLogo.png";

export const InputField = ({
  inputError,
  name,
  holder,
  value,
  onChange,
  type,
}) => {
  return (
    <div className={styles.quickSell__right__input}>
      <input
        type={type ? type : "text"}
        id={name}
        onChange={onChange}
        value={value}
        placeholder={holder}
      />
      <br />
      {inputError && <ErrorMessage inputError={inputError} />}
    </div>
  );
};

export const OTCTitle = () => {
  return (
    <div className={styles.quickSell__right__purchase__header}>
      <img src={otcLogo} alt="" />
      <p>
        Enjoy and engage in our over the counter trading to get our tokens and
        coins.
      </p>
    </div>
  );
};

export const SelectField = ({
  inputError,
  name,
  holder,
  value,
  onChange,
  children,
}) => {
  return (
    <div className={styles.quickSell__right__select}>
      <select value={value} id={name} onChange={onChange}>
        <option value="">{holder}</option>
        {children}
      </select>
      <br />
      {inputError && <ErrorMessage inputError={inputError} />}
    </div>
  );
};
const QuickSell = ({ dashboard }) => {
  const user = JSON.parse(sessionStorage.getItem("userUlta") ?? "{}").user;

  const [step, setStep] = useState(1);
  // const [address, setAddress] = useState("");
  const [currAuth, setCurrAuth] = useState("login");
  const [amount, setAmount] = useState("");
  const [disconnect, setDisconnect] = useState(false);
  const [loading] = useState("");
  // const [showButton, setShowButton] = useState("");
  // const [paymentAmount, setPaymentAmount] = useState("");
  const [paymentCrypto, setPaymentCrypto] = useState("");
  // const [document, setDocument] = useState("");
  const [crypto, setCrypto] = useState("ULIT");
  const [dasaOption, setDasaOption] = useState("swap");
  const [sellOption, setSellOption] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(true);
  // const [paymentMethod, setPaymentMethod] = useState("Coinbase Commerce");
  const [auth, setAuth] = useState(user.token ? true : false);
  const [data, setData] = useState({
    address: "",
    network: "",
    wallet: "",
    walletId: "",
    img: "",
  });

  const sellOptions = [
    {
      title: "Sell to DASA TRADER a partner of ULITCOIN.COM",
      disc: "The minimum amount to sell using DASA TRADER is $250 worth of ULIT Cryptocurrencies. You’ll get your proceeds in stablecoins USDT or USDC or cryptocurrencies LTC or DOGE. Note, each USDT stablecoin is exchanged to one $USD",
      shrtName: "dasa",
    },
    {
      title: "Sell to ULIT OTC DESK ",
      disc: "The minimum amount to sell using ULIT OTC DESK is $100,000 but typically over $1M worth of ULIT Cryptocurrencies. ",
      shrtName: "otc",
    },
    {
      title: "Sell on CATS TRADING ZONE on ULITCOIN.COM",
      disc: "Theres no minimum amount to sell using CATS TRADING ZONE to sell any type of ULIT Cryptocurrencies. You’ll get your proceeds in stablecoins USDT or USDC or cryptocurrencies LTC or DOGE. Note, each USDT stablecoin is exchanged to one $USD.",
      shrtName: "cats",
    },
    {
      title: "Sell on ULIT EXCHANGE on ULITCOIN.COM",
      disc: "Theres no minimum amount to sell using ULIT EXCHANGE to sell any type of ULIT Cryptocurrencies. You’ll get your proceeds in stablecoins USDT or USDC or any cryptocurrencies  including BTC, ETH LTC DOGE etc. Note, each USDT stablecoin is exchanged to one $USD.",
      shrtName: "exchange",
    },
  ];

  // var options = {
  //   year: "numeric",
  //   month: "long",
  //   day: "numeric",
  //   hour: "numeric",
  //   minute: "numeric",
  // };
  // const date = new Date();

  const confirm = true;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // console.log("data", data);

  const handleLogout = () => {
    sessionStorage.removeItem("userUlta");
    setAuth(false);
    setStep(1);
  };
  const handleDisconnect = () => {
    setData({
      address: "",
      network: "",
      wallet: "",
      walletId: "",
      img: "",
    });
    setStep(1);
    setDisconnect(true);
  };

  return (
    <div className={styles.quickSell}>
      <div className={styles.quickSell__left}></div>
      <div
        style={{ background: auth ? "inherit" : "#161322" }}
        className={styles.quickSell__right}
      >
        {step >= 1 && auth && (
          <div className={styles.quickSell__right__logoutBtn}>
            <div className={styles.quickSell__right__logoutBtn__back}>
              {step >= 3 && (
                <img
                  onClick={() =>
                    sellOption === "otc" && step === 4
                      ? setStep(step - 2)
                      : setStep(step - 1)
                  }
                  src={backIcon}
                  alt=""
                />
              )}
            </div>

            <div className={styles.quickSell__right__logoutBtn__main}>
              {step >= 2 && (
                <button onClick={handleDisconnect}>Disconnect Wallet</button>
              )}
              {!dashboard && <span onClick={handleLogout}>Logout</span>}
            </div>
          </div>
        )}
        {step >= 2 && auth && !dashboard && (
          <div className={styles.quickSell__right__net}>
            <p>{data.network}</p>
            <p>
              <img src={ulit} alt="" /> {data.address.substring(0, 10)}....
            </p>
          </div>
        )}

        {!auth ? (
          <>
            <div className={styles.quickSell__right__authBtn}>
              <button onClick={() => setCurrAuth("signup")}>Sign Up</button>
              <button onClick={() => setCurrAuth("login")}>Login</button>
            </div>
            {currAuth === "login" ? (
              <Login setAuth={setAuth} setCurrAuth={setCurrAuth} />
            ) : currAuth === "signup" ? (
              <Signup setAuth={setAuth} setCurrAuth={setCurrAuth} />
            ) : (
              <HelpCentre />
            )}
          </>
        ) : (
          <>
            {step === 1 && (
              <WalletConnect
                disconnect={disconnect}
                setStep={setStep}
                data={data}
                setData={setData}
              />
            )}

            {step === 2 && (
              <div className={styles.quickSell__right__option}>
                <h2>Select An Option</h2>

                {sellOptions.map((option, i) => {
                  return (
                    <div
                      onClick={() => {
                        i < 2 && setSellOption(option.shrtName);
                      }}
                      className={
                        option.shrtName === sellOption
                          ? `${styles.quickSell__right__option__item}  ${styles.quickSell__right__option__item__active}`
                          : i < 2
                          ? styles.quickSell__right__option__item
                          : `${styles.quickSell__right__option__item} ${styles.quickSell__right__option__item__disabled}`
                      }
                    >
                      <h3>{option.title}</h3>
                      <p>{option.disc}</p>
                    </div>
                  );
                })}

                <ButtonMain
                  $fill
                  width="100%"
                  height="56px"
                  radius="4px"
                  disabled={!sellOption}
                  onClick={() =>
                    sellOption === "dasa" ? setStep(3) : setStep(4)
                  }
                >
                  Proceed
                </ButtonMain>
              </div>
            )}
            {step === 3 && (
              <div className={styles.quickSell__right__option2}>
                <h2>Select An Option</h2>

                <div className={styles.quickSell__right__option2__item}>
                  <div className={styles.quickSell__right__option2__item__left}>
                    <h3>Swap ULIT Cryptocurrencies</h3>
                    <p>Swap your ULIT Cryptocurrencies using DASA Trader</p>
                  </div>

                  <ButtonMain
                    onClick={() => {
                      setStep(4);
                      setDasaOption("swap");
                    }}
                    width="117px"
                    $fill
                    radius="4px"
                  >
                    Swap
                  </ButtonMain>
                </div>
                <div className={styles.quickSell__right__option2__item}>
                  <div className={styles.quickSell__right__option2__item__left}>
                    <h3>Sell ULIT Cryptocurrencies</h3>
                    <p>
                      Sell your ULIT Cryptocurrencies to DASA Trader with a
                      minimum of $250 worth of ULIT Cryptocurrency
                    </p>
                  </div>

                  <ButtonMain
                    onClick={() => {
                      setStep(4);
                      setDasaOption("sell");
                    }}
                    width="117px"
                    $fill
                    radius="4px"
                  >
                    Sell
                  </ButtonMain>
                </div>
              </div>
            )}

            {sellOption === "dasa" ? (
              <>
                {dasaOption === "swap" ? (
                  <>
                    {step === 4 && (
                      <div className={styles.quickSell__right__purchase}>
                        <Modal
                          className="modalMain"
                          overlayClassName="modal-overlay-alt"
                          ariaHideApp={false}
                          isOpen={modalIsOpen}
                          onRequestClose={() => setModalIsOpen(false)}
                          contentLabel=""
                        >
                          <div className="getLoan__modal">
                            <div
                              onClick={() => setModalIsOpen(false)}
                              className="getLoan__modal__close"
                            >
                              <img src={closeIcon} alt="" />
                            </div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="88"
                              height="88"
                              viewBox="0 0 88 88"
                              fill="none"
                            >
                              <path
                                d="M44.0031 0.25C55.6072 0.25 66.736 4.85969 74.9413 13.065C83.1466 21.2703 87.7562 32.3991 87.7562 44.0031C87.7562 55.6072 83.1466 66.736 74.9413 74.9413C66.736 83.1466 55.6072 87.7563 44.0031 87.7563C32.3991 87.7563 21.2703 83.1466 13.065 74.9413C4.85969 66.736 0.25 55.6072 0.25 44.0031C0.25 32.3991 4.85969 21.2703 13.065 13.065C21.2703 4.85969 32.3991 0.25 44.0031 0.25ZM50.5656 27.1125C53.8156 27.1125 56.4531 24.8563 56.4531 21.5125C56.4531 18.1688 53.8094 15.9125 50.5656 15.9125C47.3156 15.9125 44.6906 18.1688 44.6906 21.5125C44.6906 24.8563 47.3156 27.1125 50.5656 27.1125ZM51.7094 62.2813C51.7094 61.6125 51.9406 59.875 51.8094 58.8875L46.6719 64.8C45.6094 65.9188 44.2781 66.6938 43.6531 66.4875C43.3696 66.3831 43.1326 66.181 42.9848 65.9175C42.837 65.654 42.7881 65.3464 42.8469 65.05L51.4094 38C52.1094 34.5688 50.1844 31.4375 46.1031 31.0375C41.7969 31.0375 35.4594 35.4063 31.6031 40.95C31.6031 41.6125 31.4781 43.2625 31.6094 44.25L36.7406 38.3313C37.8031 37.225 39.0406 36.4438 39.6656 36.6562C39.9736 36.7668 40.2259 36.9936 40.3684 37.2881C40.511 37.5825 40.5324 37.9212 40.4281 38.2313L31.9406 65.15C30.9594 68.3 32.8156 71.3875 37.3156 72.0875C43.9406 72.0875 47.8531 67.825 51.7156 62.2813H51.7094Z"
                                fill="#E6A716"
                              />
                            </svg>
                            <h3>Important Information</h3>
                            <p>
                              Provide a correct Wallet Address and blockchain
                              network to receive assets
                            </p>

                            <div
                              className={
                                styles.quickSell__right__purchase__modalBtn
                              }
                            >
                              <span onClick={() => setStep(3)}>
                                Cancel Application
                              </span>

                              <button
                                style={{ width: "150px", margin: 0 }}
                                onClick={() => {
                                  setModalIsOpen(false);
                                }}
                              >
                                Continue
                              </button>
                            </div>
                          </div>
                        </Modal>
                        <div className={styles.quickSell__right__purchase__img}>
                          <img src={dasaLogo} alt="" />
                        </div>
                        <Select
                          name="Cryptocurrency"
                          value={crypto}
                          holder="Select ULIT Cryptocurrency"
                          label="What Cryptocurrency do you want to sell?"
                          onChange={(e) => setCrypto(e.target.value)}
                        >
                          <option value="ULIT">ULIT</option>
                        </Select>
                        <Input
                          value={amount}
                          onChange={(e) => setAmount(e.target.value)}
                          name="amount"
                          holder="Amount"
                          type="number"
                          label="What amount do you want to sell?"
                        />
                        <p className={styles.quickSell__right__purchase__min}>
                          Minimum amount is <span>$250</span>
                        </p>

                        <Input
                          value={10000}
                          // onChange={(e) => setAmount(e.target.value)}
                          name="unit"
                          holder="unit"
                          label="Units of Assets to sell"
                          type="number"
                        />
                        <SelectField
                          onChange={(e) => setPaymentCrypto(e.target.value)}
                          name="Payment"
                          holder="Select Payment Currency"
                          value={paymentCrypto}
                        >
                          <option value="usdt">USDT</option>
                          <option value="usdc">USDC</option>
                          <option value="btc">BTC</option>
                          <option value="eth">ETH</option>
                          <option value="ltc">LTC</option>
                          <option value="doge">DOGE</option>
                          {/* <option value="BNB">BNB</option> */}
                        </SelectField>

                        <div
                          className={
                            styles.quickSell__right__purchase__inputText
                          }
                        >
                          <span style={{ color: "#fff" }}>250</span>{" "}
                          <span>| Currency</span>
                        </div>
                        <div
                          className={
                            styles.quickSell__right__purchase__inputText
                          }
                        >
                          ULIT (TBA) | ULIT{" "}
                          <span>(see confirmation invoice)</span>
                        </div>

                        <SelectField
                          name="Network"
                          holder="Select Blockchain Network"
                          // value={paymentCrypto}
                        >
                          <option value="BNB Smart Chain (BEP 20)">
                            BNB Smart Chain (BEP 20)
                          </option>
                        </SelectField>

                        <InputField
                          name="address"
                          holder="Enter wallet address"
                        />

                        <div
                          className={styles.quickSell__right__purchase__exchage}
                        >
                          Exchange Rate <span>1 $ = 0.00483 BTC</span>
                        </div>

                        <div
                          className={styles.quickSell__right__purchase__terms}
                        >
                          <input type="checkbox" />{" "}
                          <p>
                            I Agree to <span>ULIT conditions</span> and to{" "}
                            <span>DASA Trader terms</span>, and to the{" "}
                            <span>purchase rate</span> for which my sale is
                            settled for the cryptocurrencies that I have placed
                            for sale
                          </p>
                        </div>

                        <div style={{ marginTop: 40 }}>
                          <button
                            className={styles.quickSell__btn}
                            onClick={() => setStep(5)}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {loading ? (
                              <ReactLoading
                                color="white"
                                width={30}
                                height={30}
                                type="spin"
                              />
                            ) : (
                              "Next"
                            )}
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {step === 4 && (
                      <div className={styles.quickSell__right__purchase}>
                        <div className={styles.quickSell__right__purchase__img}>
                          <img src={dasaLogo} alt="" />
                        </div>
                        <span
                          className={styles.quickSell__right__purchase__pos}
                        >
                          From
                        </span>
                        <SelectField
                          name="Cryptocurrency"
                          value={crypto}
                          holder="Select ULIT Cryptocurrency"
                          label="What Cryptocurrency do you want to sell?"
                          onChange={(e) => setCrypto(e.target.value)}
                        >
                          <option value="usdt">USDT</option>
                          <option value="usdc">USDC</option>
                          <option value="btc">BTC</option>
                          <option value="eth">ETH</option>
                          <option value="bnb">BNB</option>
                          <option value="ulit">ULIT COIN</option>
                          <option value="dulc">DULC COIN</option>
                          <option value="txla">TXLA</option>
                          <option value="doge">DOGE</option>
                          <option value="xrp">XRP</option>
                        </SelectField>

                        <div
                          className={
                            styles.quickSell__right__purchase__inputAndText
                          }
                        >
                          <InputField
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            name="amount"
                            holder="Amount"
                            type="number"
                            label="What amount do you want to sell?"
                          />

                          <span>USDT </span>
                        </div>
                        <p className={styles.quickSell__right__purchase__avail}>
                          Available: 2,000,000.00
                        </p>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            margin: "50px 0",
                          }}
                        >
                          <img src={arrowDown} alt="" />
                        </div>

                        <span
                          className={styles.quickSell__right__purchase__pos}
                        >
                          To
                        </span>
                        <SelectField
                          name="Cryptocurrency"
                          value={crypto}
                          holder="Select ULIT Cryptocurrency"
                          label="What Cryptocurrency do you want to sell?"
                          onChange={(e) => setCrypto(e.target.value)}
                        >
                          <option value="ulit">ULIT COIN</option>
                          <option value="dulc">DULC COIN</option>
                          <option value="txla">TXLA</option>
                        </SelectField>

                        <div
                          className={
                            styles.quickSell__right__purchase__inputAndText
                          }
                        >
                          <InputField
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            name="amount"
                            holder="Amount"
                            type="number"
                            label="What amount do you want to sell?"
                          />

                          <span>ULIT COIN</span>
                        </div>

                        <div
                          className={styles.quickSell__right__purchase__exchage}
                        >
                          Exchange Rate <span>1000 FULC = 1 DULC</span>
                        </div>

                        <div
                          className={styles.quickSell__right__purchase__terms}
                        >
                          <input type="checkbox" />{" "}
                          <p>
                            I Agree to <span>ULIT conditions</span> and to{" "}
                            <span>DASA Trader terms</span>, and to the{" "}
                            <span>purchase rate</span> for which my sale is
                            settled for the cryptocurrencies that I have placed
                            for sale
                          </p>
                        </div>

                        <div style={{ marginTop: 40 }}>
                          <button
                            className={styles.quickSell__btn}
                            onClick={() => setStep(5)}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {loading ? (
                              <ReactLoading
                                color="white"
                                width={30}
                                height={30}
                                type="spin"
                              />
                            ) : (
                              "Next"
                            )}
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {step === 4 && (
                  <div className={styles.quickSell__right__purchase}>
                    <OTCTitle />
                    <Select
                      name="Cryptocurrency"
                      value={crypto}
                      holder="Select ULIT Cryptocurrency"
                      label="What Cryptocurrency do you want to sell?"
                      onChange={(e) => setCrypto(e.target.value)}
                    >
                      <option value="ULIT">ULIT</option>
                    </Select>
                    <Input
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      name="amount"
                      holder="Amount"
                      type="number"
                      label="What amount do you want to sell?"
                    />
                    <p className={styles.quickSell__right__purchase__min}>
                      Minimum amount is <span>$100,000</span>
                    </p>

                    <Input
                      value={10000}
                      // onChange={(e) => setAmount(e.target.value)}
                      name="unit"
                      holder="unit"
                      label="Units of Assets to sell"
                      type="number"
                    />
                    <SelectField
                      onChange={(e) => setPaymentCrypto(e.target.value)}
                      name="Payment"
                      holder="Select Payment Currency"
                      value={paymentCrypto}
                    >
                      <option value="usdt">USDT</option>
                      <option value="usdc">USDC</option>
                      <option value="btc">BTC</option>
                      <option value="eth">ETH</option>
                      <option value="ltc">LTC</option>
                      <option value="doge">DOGE</option>
                      {/* <option value="BNB">BNB</option> */}
                    </SelectField>

                    <div
                      className={styles.quickSell__right__purchase__inputText}
                    >
                      <span style={{ color: "#fff" }}>250</span>{" "}
                      <span>| Currency</span>
                    </div>
                    <div
                      className={styles.quickSell__right__purchase__inputText}
                    >
                      ULIT (TBA) | ULIT <span>(see confirmation invoice)</span>
                    </div>

                    <SelectField
                      name="Network"
                      holder="Select Blockchain Network"
                      // value={paymentCrypto}
                    >
                      <option value="BNB Smart Chain (BEP 20)">
                        BNB Smart Chain (BEP 20)
                      </option>
                    </SelectField>

                    <InputField name="address" holder="Enter wallet address" />

                    <div className={styles.quickSell__right__purchase__exchage}>
                      Exchange Rate <span>1 $ = 0.00483 BTC</span>
                    </div>

                    <div className={styles.quickSell__right__purchase__terms}>
                      <input type="checkbox" />{" "}
                      <p>
                        I Agree to <span>ULIT conditions</span> and to{" "}
                        <span>DASA Trader terms</span>, and to the{" "}
                        <span>purchase rate</span> for which my sale is settled
                        for the cryptocurrencies that I have placed for sale
                      </p>
                    </div>

                    <div style={{ marginTop: 40 }}>
                      <button
                        className={styles.quickSell__btn}
                        onClick={() => setStep(5)}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {loading ? (
                          <ReactLoading
                            color="white"
                            width={30}
                            height={30}
                            type="spin"
                          />
                        ) : (
                          "Next"
                        )}
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}

            {step === 5 && (
              <Verify sellOption={sellOption} setStepMain={setStep} />
            )}

            {step === 6 && (
              <>
                {!confirm ? (
                  <div className={styles.quickSell__right__await}>
                    <h2>Awaiting Delivery Confirmation</h2>
                    <p>
                      Your payment has been sent and is currently under review
                      and checks. Once verified, you will see your delivery
                      confirmation here, once your sold token has been released
                      after the receipt of your sale has been confirmed by the
                      Admin. This may take few hours and up to 3 days. Kindly
                      wait patiently. Once released, you will see the released
                      purchased token amount in your wallet.
                    </p>
                    <p>
                      You can revisit this page for an update on your
                      transaction by simply clicking the QUICK Sell Button in
                      ULITCOIN.COM Homepage
                    </p>
                    <p>
                      The delivery of your purchase would be made and the
                      confirmation will appear in this page
                    </p>
                    <p>
                      In the event that you wish to contact ULIT, please email
                      us at myorder@ulitcoin.com
                    </p>
                    <Link to="/login">
                      <button className={styles.quickSell__btn}>
                        Go Back To Dashboard
                      </button>
                    </Link>
                  </div>
                ) : (
                  // <div className={styles.quickSell__right__comfirm}>
                  //   <h3>Delivery Confirmation</h3>
                  //   <h4>
                  //     You have received your purchased token 25312.046 ULIT COIN
                  //   </h4>
                  //   <div className={styles.quickSell__right__comfirm__main}>
                  //     <p>Payment details</p>
                  //     <div
                  //       className={styles.quickSell__right__comfirm__main__item}
                  //     >
                  //       Payment Method <span>Coinbase Commerce</span>
                  //     </div>
                  //     <div
                  //       className={styles.quickSell__right__comfirm__main__item}
                  //     >
                  //       Total amount <span>$10,000 (10,000 USDT)</span>
                  //     </div>

                  //     <div
                  //       className={styles.quickSell__right__comfirm__main__item}
                  //     >
                  //       Time <span>15 Jan 2022, 21:16 PM</span>
                  //     </div>
                  //     <div
                  //       className={styles.quickSell__right__comfirm__main__item}
                  //     >
                  //       Paid Invoice confirmation for <span>John Doe</span>
                  //     </div>
                  //     <div
                  //       className={styles.quickSell__right__comfirm__main__item}
                  //     >
                  //       Invoice number <span>001</span>
                  //     </div>
                  //     <p>
                  //       Transaction ID <span>065468748942</span>
                  //     </p>
                  //   </div>

                  //   <div className={styles.quickSell__right__comfirm__btnCon}>
                  //     <div
                  //       className={
                  //         styles.quickSell__right__comfirm__btnCon__download
                  //       }
                  //     >
                  //       <img src={downloadIcon} alt="" /> Download Receipt PDF
                  //     </div>
                  //     <button
                  //       className={styles.quickSell__btn}
                  //       // onClick={() => setStep()}
                  //     >
                  //       View Wallet
                  //     </button>
                  //   </div>
                  // </div>
                  <div className={styles.quickSell__right__comfirm}>
                    <img style={{ marginTop: 50 }} src={checkIcon} alt="" />
                    <h4>Cryptocurrency Sold Successfully</h4>

                    <span style={{ marginTop: 30 }}>Transaction Hash:</span>
                    <span className={styles.quickSell__right__comfirm__hash}>
                      1626G44646D55S44D44A4F4F4V444FF4A33G3D
                      <img src={copy} alt="" />
                    </span>

                    <div className={styles.quickSell__right__comfirm__btns}>
                      <button
                        onClick={() => setStep(3)}
                        style={{
                          width: "200px",
                          background: "transparent",
                          border: "1px solid #9475D4",
                        }}
                      >
                        Back
                      </button>
                      <button
                        onClick={() => setStep(7)}
                        style={{ width: "200px" }}
                      >
                        View Details
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}

            {step === 7 && (
              <div className={styles.quickSell__right__comfirm}>
                <img src="" alt="" />
                <h3>Receipt Details</h3>

                <div className={styles.quickSell__right__comfirm__main}>
                  <div className={styles.quickSell__right__comfirm__main__item}>
                    Cryptocurrency Sold <span>ULIT COIN</span>
                  </div>
                  <div className={styles.quickSell__right__comfirm__main__item}>
                    Amount paid to: <span>DASA Trader</span>
                  </div>

                  <div className={styles.quickSell__right__comfirm__main__item}>
                    Transaction Amount: <span>$1,000 (1,000 BTC)</span>
                  </div>
                  <div className={styles.quickSell__right__comfirm__main__item}>
                    Wallet Address: <span>YEFBFDgihewo3843nkffss...</span>
                  </div>
                  <div className={styles.quickSell__right__comfirm__main__item}>
                    Exchange Rate: <span>1000 ULC = 1 USDT</span>
                  </div>
                  <div className={styles.quickSell__right__comfirm__main__item}>
                    To Sell <span>ULIT COIN (TBA)</span>
                  </div>
                  <div className={styles.quickSell__right__comfirm__main__item}>
                    Exchange Fee: <span>$ 50</span>
                  </div>
                  <div className={styles.quickSell__right__comfirm__main__item}>
                    Network Fee: <span>$ 25</span>
                  </div>
                  <div className={styles.quickSell__right__comfirm__main__item}>
                    Date,Time: <span>15 Jan 2022, 21:16 PM</span>
                  </div>
                  <div className={styles.quickSell__right__comfirm__main__item}>
                    Status <span>Completed</span>
                  </div>

                  <p>
                    Transaction ID <span>065468748942</span>
                  </p>
                </div>

                <div className={styles.quickSell__right__comfirm__btnCon}>
                  <button
                    className={styles.quickSell__btn}
                    // onClick={() => setStep()}
                  >
                    Proceed to Dashboard
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default QuickSell;
