import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../suresystem/css/suresystem.module.scss";
import StepSixIcon from "../../../assets/surestepsixicon.svg";
import OtpInput from "react-otp-input";
import Check from "../../../assets/checkcircle.svg";
import { Modal, ModalBody } from "reactstrap";
import { GET_SERVICE } from "../../../shared/services/backend";
import { ToastContainer, toast } from "react-toastify";
import ReactLoading from "react-loading";
import "react-toastify/dist/ReactToastify.css";
const SureStepSix = () => {
  const [otp, setOtp] = useState("");
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const Verify = async () => {
    setLoading(true);
    const endpoint = `/sure-system/user/repayment/verify-dasa-sa-otp-code/${otp}`;
    try {
      const response = await GET_SERVICE(endpoint);
      setLoading(false);
      if (response.data.code === 200 && response.data.status === true) {
        setModal(!modal);
      } else {
        toast.error(response.data.message);
      }
    } catch (e) {
      toast.error(`Network error, Kindly check internet connections`);
    }
  };

  return (
    <div className={styles.main}>
      <div className={styles.sure}>SURE SYSTEM</div>
      <div className={styles.parent}>
        <div className={styles.box}>
          <img src={StepSixIcon} className={styles.stepIcon} alt="horse" />
          <div className={styles.sureTitle}>
            Input Your DASA Super Admin OTP Code
          </div>
          <div className={styles.Surelist}>
            Enter the 5 digit code sent to you via Messenger in order to
            proceed.
          </div>
          <div>
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              containerStyle={{
                justifyContent: "space-between",
                marginTop: "2.5em",
              }}
              inputStyle={{
                width: "60px",
                height: "60px",
                background: "none",
                border: "1px solid #9475d4",
                borderRadius: "7px",
                color: "#9475d4",
                fontSize: "2em",
              }}
              renderSeparator={<span></span>}
              renderInput={(props) => <input {...props} />}
            />
            <div className={styles.resend}>Resend Request</div>
          </div>
          <div className={styles.receiptBtnDiv}>
            <button className={styles.btn} onClick={Verify}>
              {loading ? (
                <ReactLoading
                  color="white"
                  width={25}
                  height={25}
                  type="spin"
                />
              ) : (
                "Next"
              )}
            </button>
          </div>
        </div>
      </div>

      <Modal
        isOpen={modal}
        toggle={Verify}
        size="lg"
        className={styles.modalParent}
      >
        <ModalBody className={styles.modalContent}>
          <div className={styles.successIconDiv}>
            <img src={Check} className={styles.successIcon} alt="horse" />
          </div>
          <div className={styles.modalTitle}>Liquidation Successful</div>
          <div className={styles.modalDesc}>
            Your assets have been successfully liquidated. You can reinvest or
            repay your outstanding loan.
          </div>
          <div className={styles.btnRow}>
            <div className={styles.backToDashboard}>Back to Dashboard</div>
            <button className={styles.modalBtn}>Continue</button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default SureStepSix;
