import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../suresystem/css/suresystem.module.scss";
import StepFourIcon from "../../../assets/surestepfouricon.svg";
import { POST_SERVICE } from "../../../shared/services/backend";
import { ToastContainer, toast } from "react-toastify";
import ReactLoading from "react-loading";
import "react-toastify/dist/ReactToastify.css";
const SureStepFour = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const gotoSureStepFive = async () => {
    setLoading(true);
    const endpoint = `/sure-system/user/repayment/request-dasa-sa-otp-code`;
    try {
      const response = await POST_SERVICE(endpoint);
      setLoading(false);
      if (
        response.data.code === 200 ||
        (response.data.code === 201 && response.data.status === true)
      ) {
        toast.success(response.data.message);
        navigate("/dashboard/get-loan/loans/step-five");
      } else {
        toast.error(response.data.message);
      }
    } catch (e) {
      toast.error(`Network error, Kindly check internet connections`);
    }
  };

  return (
    <div className={styles.main}>
      <div className={styles.sure}>SURE SYSTEM</div>
      <div className={styles.parent}>
        <div className={styles.box}>
          <img src={StepFourIcon} className={styles.stepIcon} alt="horse" />
          <div className={styles.sureTitle}>
            Request DASA Super Admin OTP Code
          </div>
          <div className={styles.Surelist}>
            Click on the Next button to send in a request to the DASA Super
            Admin for an OTP code in order to proceed.
          </div>
          <div className={styles.Surelist}>
            It may take up to 3 days to get your code. This is due to request
            check and processing by the Super Admin. Kindly wait patiently.
          </div>

          <div className={styles.receiptBtnDiv}>
            <button className={styles.btn} onClick={gotoSureStepFive}>
              {loading ? (
                <ReactLoading
                  color="white"
                  width={25}
                  height={25}
                  type="spin"
                />
              ) : (
                "Next"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SureStepFour;
