import ReactPaginate from "react-paginate";
import "./buyNow.scss";
import Chart from "../../component/chart/Chart";
import { Link } from "react-router-dom";
import backIcon from "../../assets/back.svg";
// import divitIcon from "../../assets/divit.png";
import { ButtonMain } from "../../component/StyledComponent";
import { useState } from "react";
import { Input, Select } from "../getLoan/GetLoan";
import Modal from "react-modal";
import closeIcon from "../../assets/close.svg";
import ulitCoin from "../../assets/ulitCoin.png";
import ulitCoin2 from "../../assets/ulitCoin2.png";

const BuyNow = () => {
  const [step, setStep] = useState(1);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpen2, setModalIsOpen2] = useState(false);

  const user = JSON.parse(sessionStorage.getItem("userUlta") ?? "{}").user;
  var options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  const date = new Date();

  const pageCount = 5;
  const transaction = [
    {
      token: "DIVIT Token",
      date: "2022/12/09",
      quantity: "300,000",
      id: "U334089LIT",
      amount: "$25.00",
      price: "$0.1242600287",
      action: "Buy",
    },
    {
      token: "DIVIT Token",
      date: "2022/12/09",
      quantity: "300,000",
      id: "U334089LIT",
      amount: "$25.00",
      price: "$0.1242600287",
      action: "Buy",
    },
    {
      token: "DIVIT Token",
      date: "2022/12/09",
      quantity: "300,000",
      id: "U334089LIT",
      amount: "$25.00",
      price: "$0.1242600287",
      action: "Buy",
    },
    {
      token: "DIVIT Token",
      date: "2022/12/09",
      quantity: "300,000",
      id: "U334089LIT",
      amount: "$25.00",
      price: "$0.1242600287",
      action: "Buy",
    },
    {
      token: "DIVIT Token",
      date: "2022/12/09",
      quantity: "300,000",
      id: "U334089LIT",
      amount: "$25.00",
      price: "$0.1242600287",
      action: "Buy",
    },
  ];
  const Token = () => {
    return (
      <div className="buyNow__tokens__item">
        <div className="buyNow__tokens__item__token">
          <div className="buyNow__tokens__item__token__left">
            <img src={ulitCoin2} alt="" />
            <div className="buyNow__tokens__item__token__left__disc">
              <h4>ULIT COIN</h4>
              <p>ULC</p>
            </div>
          </div>

          <div className="buyNow__tokens__item__token__right">
            <h4>$4,412.65</h4>
            <p>+1.6%</p>
          </div>
        </div>

        <div className="buyNow__tokens__item__main">
          <svg
            width="100%"
            height="128"
            viewBox="0 0 292 128"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 108.571L8.99013 116.319C9.4345 116.75 10.1596 116.678 10.5103 116.168L19.8774 102.543C20.2584 101.989 21.0669 101.962 21.4849 102.489L34.4839 118.874C34.9167 119.42 35.761 119.367 36.1222 118.771L49.2125 97.1935C49.6116 96.5357 50.5729 96.5561 50.9437 97.2302L61.6113 116.625C61.7871 116.944 62.1228 117.143 62.4875 117.143H71.8177C72.322 117.143 72.7474 116.767 72.8099 116.267L74.595 101.987C74.6384 101.64 74.8597 101.341 75.1788 101.199L86.7209 96.0332C87.1397 95.8457 87.6322 95.9673 87.9157 96.3281L94.451 104.645C94.8677 105.176 95.6792 105.15 96.0613 104.594L110.912 82.9943C110.974 82.9035 111.021 82.803 111.051 82.6968L123.571 37.8738C123.691 37.4417 124.085 37.1429 124.534 37.1429H129.88C130.334 37.1429 130.731 36.8373 130.847 36.3985L139.593 3.32573C139.849 2.35806 141.21 2.32486 141.513 3.27891L151.419 34.4972C151.702 35.3902 152.948 35.4382 153.299 34.5697L161.181 15.0802C161.515 14.2564 162.676 14.2439 163.027 15.0603L166.128 22.2763C166.466 23.0632 167.572 23.0886 167.946 22.3181L177.869 1.87175C178.236 1.1174 179.312 1.12148 179.672 1.87858L187.268 17.838C187.472 18.2653 187.947 18.4916 188.407 18.3801L198.901 15.8362C199.216 15.7598 199.549 15.8412 199.794 16.0546L208.984 24.0749C209.141 24.212 209.252 24.3948 209.3 24.5977L218.295 62.5368C218.522 63.4963 219.854 63.5822 220.203 62.6598L229.233 38.7811C229.506 38.0571 230.464 37.9053 230.948 38.5091L236.427 45.34C236.617 45.5766 236.904 45.7143 237.207 45.7143H251.863C252.222 45.7143 252.553 45.5224 252.731 45.2114L256.082 39.3615C256.535 38.5718 257.722 38.7481 257.925 39.6353L262.087 57.7948C262.191 58.2493 262.595 58.5714 263.061 58.5714H269.341C269.8 58.5714 270.2 58.8837 270.312 59.3289L277.6 88.48C277.777 89.189 278.63 89.4746 279.198 89.0153L286.531 83.0903C286.717 82.9399 286.843 82.7277 286.886 82.4922L290 65.4545"
              stroke="#40BF6A"
              stroke-linecap="round"
            />
            <path
              d="M8.99632 116.982L1 109.192V127C1 127.552 1.44772 128 2 128H291C291.552 128 292 127.552 292 127V66.0898L287.171 83.0273C287.115 83.2247 286.999 83.4 286.84 83.5294L279.457 89.523C278.889 89.9845 278.034 89.6987 277.857 88.9881L270.56 59.6647C270.449 59.219 270.049 58.9061 269.59 58.9061H263.304C262.838 58.9061 262.433 58.5834 262.329 58.1285L258.162 39.8595C257.96 38.9706 256.77 38.7945 256.319 39.5866L252.963 45.4709C252.786 45.7829 252.454 45.9755 252.095 45.9755H237.427C237.123 45.9755 236.835 45.8371 236.646 45.5994L231.162 38.7301C230.678 38.1238 229.718 38.2757 229.444 39.0018L220.406 63.019C220.058 63.9432 218.723 63.8575 218.497 62.8964L209.492 24.7358C209.445 24.5333 209.335 24.3508 209.178 24.2136L199.979 16.1468C199.734 15.932 199.399 15.85 199.083 15.9271L188.582 18.4847C188.121 18.597 187.645 18.37 187.442 17.9413L179.838 1.88922C179.478 1.12989 178.399 1.12581 178.033 1.8824L168.101 22.4457C167.728 23.2184 166.619 23.193 166.281 22.4039L163.177 15.146C162.827 14.3275 161.662 14.34 161.33 15.1658L153.44 34.7679C153.09 35.6384 151.841 35.5905 151.559 34.6957L141.642 3.29601C141.34 2.34028 139.977 2.3734 139.722 3.34265L130.966 36.6096C130.851 37.0489 130.454 37.3551 129.999 37.3551H124.648C124.199 37.3551 123.805 37.6545 123.685 38.0873L111.153 83.1707C111.123 83.2766 111.076 83.3769 111.014 83.4676L96.1498 105.191C95.7679 105.749 94.9535 105.775 94.5368 105.242L87.9964 96.8787C87.7128 96.5161 87.2184 96.3939 86.7986 96.5827L75.2459 101.778C74.9275 101.921 74.7068 102.22 74.6637 102.566L72.8759 116.936C72.8137 117.436 72.3881 117.812 71.8836 117.812H62.5453C62.1799 117.812 61.8436 117.613 61.6681 117.292L50.9901 97.7861C50.6198 97.1096 49.6555 97.0892 49.2569 97.7494L36.155 119.45C35.794 120.048 34.9469 120.101 34.5141 119.553L21.5046 103.075C21.0867 102.546 20.2753 102.574 19.8944 103.13L10.5194 116.831C10.1688 117.343 9.44099 117.416 8.99632 116.982Z"
              fill="url(#paint0_linear_629_24015)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_629_24015"
                x1="146.5"
                y1="0"
                x2="146.5"
                y2="128"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#40BF6A" stop-opacity="0.2" />
                <stop offset="1" stop-color="#40BF6A" stop-opacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </div>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: 10,
            justifyContent: "space-between",
          }}
          className="buyNow__tokens__item__amo"
        >
          <p>
            Quantity: <span>1000000000</span>{" "}
          </p>
          <p>Current Price: $0.13040424344</p>
        </div>
      </div>
    );
  };
  return (
    <div className="buyNow">
      <Modal
        className="modalMain"
        overlayClassName="modal-overlay-alt"
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel=""
      >
        <div className="buyNow__modal">
          <div
            onClick={() => setModalIsOpen(false)}
            className="buyNow__modal__close"
          >
            <img src={closeIcon} alt="" />
          </div>
          <div className="buyNow__modal__congrats">
            <h3>Congrats!</h3>
            <h4>
              Your payment is being processed and we have received your amount.
            </h4>
            <div className="buyNow__modal__congrats__main">
              <p>Payment details</p>
              <div className="buyNow__modal__congrats__main__item">
                Payment Method <span>Loan</span>
              </div>
              <div className="buyNow__modal__congrats__main__item">
                Total amount <span>$10,000 (10,000 ULC)</span>
              </div>

              <div className="buyNow__modal__congrats__main__item">
                Time <span>{date.toLocaleDateString("en-US", options)}</span>
              </div>
              <div className="buyNow__modal__congrats__main__item">
                Paid Invoice confirmation for{" "}
                <span>{user?.user?.display_name}</span>
              </div>
              {/* <div className="buyNow__modal__congrats__main__item}>
                    Invoice number <span>001</span>
                  </div> */}
              <p>
                Transaction ID <span>065468748942</span>
              </p>
            </div>

            <div className="buyNow__modal__congrats__btnCon">
              {/* <div
                    className={
                      styles.buyNow__modal__congrats__btnCon__download
                    }
                  >
                    <img src={downloadIcon} alt="" /> Download Receipt PDF
                  </div> */}
              <ButtonMain
                $fill
                width="200px"
                height="48px"
                onClick={() => {
                  setModalIsOpen(false);
                  setStep(1);
                }}
              >
                Continue
              </ButtonMain>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        className="modalMain"
        overlayClassName="modal-overlay-alt"
        ariaHideApp={false}
        isOpen={modalIsOpen2}
        onRequestClose={() => setModalIsOpen2(false)}
        contentLabel=""
      >
        <div className="buyNow__modal">
          <div
            onClick={() => setModalIsOpen2(false)}
            className="buyNow__modal__close"
          >
            <img src={closeIcon} alt="" />
          </div>
          <div className="buyNow__modal__congrats">
            <h3>Congrats!</h3>
            <h4>
              You have successfully liquidated 2500000 ULC (10,000 ULC) to your
              UAP Balance.
            </h4>
            <div className="buyNow__modal__congrats__main">
              <p>Transaction details</p>

              <div className="buyNow__modal__congrats__main__item">
                Liquidated amount <span>$10,000 (250000 ULC)</span>
              </div>

              <div className="buyNow__modal__congrats__main__item">
                Time <span>{date.toLocaleDateString("en-US", options)}</span>
              </div>
              <div className="buyNow__modal__congrats__main__item">
                Paid Invoice confirmation for{" "}
                <span>{user?.user?.display_name}</span>
              </div>
              {/* <div className="buyNow__modal__congrats__main__item}>
                    Invoice number <span>001</span>
                  </div> */}
              <p>
                Transaction ID <span>065468748942</span>
              </p>
            </div>

            <div className="buyNow__modal__congrats__btnCon">
              {/* <div
                    className={
                      styles.buyNow__modal__congrats__btnCon__download
                    }
                  >
                    <img src={downloadIcon} alt="" /> Download Receipt PDF
                  </div> */}
              <ButtonMain
                $fill
                width="200px"
                height="48px"
                onClick={() => {
                  setModalIsOpen2(false);
                  setStep(1);
                }}
              >
                Continue
              </ButtonMain>
            </div>
          </div>
        </div>
      </Modal>
      {step === 1 && (
        <>
          <div className="buyNow__header">
            <Link to={-1}>
              <img className="spotTrading__back" src={backIcon} alt="" />
            </Link>

            <div className="buyNow__header__right">
              <ButtonMain
                onClick={() => setStep(2)}
                $fill
                radius="13px"
                width="153px"
              >
                Buy Now
              </ButtonMain>
              <ButtonMain
                onClick={() => setStep(3)}
                $fill
                radius="13px"
                width="153px"
                style={{ background: "#DF2040" }}
              >
                Sell Now
              </ButtonMain>
            </div>
          </div>

          <div className="buyNow__balance">
            <div className="buyNow__balance__left">
              <h3>Wallet Balance</h3>
              <b>
                40,000 <span>ULIT</span>
              </b>
              <p>$4000.00</p>
            </div>

            <img src={ulitCoin} alt="" />
          </div>

          <div className="buyNow__tokens">
            <Token />
            <Token />
            <Token />
            <Token />
            <Token />
            <Token />
          </div>

          <Chart title="ULC Coin" />

          <div className="buyNow__table">
            <div className="buyNow__table__header">
              <h2 className="buyNow__table__header__title">
                Transaction History
              </h2>
            </div>
            <div className="buyNow__table__body">
              <div className="buyNow__table__row head">
                <div
                  style={{ width: "8%" }}
                  className="buyNow__table__row__head"
                >
                  #
                </div>
                <div
                  style={{ width: "15%" }}
                  className="buyNow__table__row__head"
                >
                  Token
                </div>
                <div
                  style={{ width: "15%" }}
                  className="buyNow__table__row__head"
                >
                  Date
                </div>
                <div
                  style={{ width: "13%" }}
                  className="buyNow__table__row__head"
                >
                  Quantity
                </div>
                <div
                  style={{ width: "12%" }}
                  className="buyNow__table__row__head"
                >
                  Amount
                </div>
                <div
                  style={{ width: "12%" }}
                  className="buyNow__table__row__head"
                >
                  Transaction ID
                </div>
                <div
                  style={{ width: "15%" }}
                  className="buyNow__table__row__head"
                >
                  Price
                </div>
                <div
                  style={{ width: "10%" }}
                  className="buyNow__table__row__head"
                >
                  Action
                </div>
              </div>

              {transaction.map((item, i) => {
                return (
                  <div key={i} className="buyNow__table__row">
                    <div
                      style={{ width: "8%" }}
                      className="buyNow__table__row__data"
                    >
                      {i + 1}
                    </div>
                    <div
                      style={{ width: "15%" }}
                      className="buyNow__table__row__data"
                    >
                      {item?.token}
                    </div>
                    <div
                      style={{ width: "15%" }}
                      className="buyNow__table__row__data"
                    >
                      {item?.date}
                    </div>
                    <div
                      style={{ width: "13%" }}
                      className="buyNow__table__row__data"
                    >
                      {item?.quantity}
                    </div>
                    <div
                      style={{ width: "12%" }}
                      className="buyNow__table__row__data"
                    >
                      {item?.amount}
                    </div>
                    <div
                      style={{ width: "12%" }}
                      className="buyNow__table__row__data"
                    >
                      {item?.id}
                    </div>
                    <div
                      style={{ width: "15%" }}
                      className="buyNow__table__row__data"
                    >
                      {item?.price}
                    </div>
                    <div
                      style={{ width: "10%" }}
                      className="buyNow__table__row__data"
                    >
                      {item?.action}
                    </div>
                  </div>
                );
              })}

              {pageCount > 1 && (
                <div style={{ marginTop: 20 }}>
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    pageCount={pageCount}
                    breakLabel="..."
                    //   renderOnZeroPageCount={null}
                    //   onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    containerClassName={"paginationBttns"}
                    previousLinkClassName={"previousBttn"}
                    nextLinkClassName={"nextBttn"}
                    disabledClassName={"paginationDisabled"}
                    activeClassName={"paginationActive"}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}
      {step === 2 && (
        <div className="buyNow__purchase">
          <img src={backIcon} alt="back" onClick={() => setStep(1)} />

          <div className="buyNow__purchase__main">
            <h3>Make Payment To Purchase</h3>

            <Select
              name="Cryptocurrencies"
              label="Select ULIT Cryptocurrencies"
            >
              <option value="ULIT">ULIT</option>
            </Select>
            <Input
              label="Amount"
              //   value={amount}
              //   onChange={(e) => setAmount(e.target.value)}
              name="Amount"
              holder="Amount"
              type="number"
            />

            <div className="buyNow__purchase__main__avail">
              MCAP Available Balance: <span>50,000 USD</span>{" "}
            </div>

            <div className="getLoan__inputText">
              0.987665 <span>ULC</span>{" "}
            </div>

            <ButtonMain
              style={{ marginTop: 50 }}
              $fill
              width="100%"
              height="56px"
              radius="4px"
              onClick={() => setModalIsOpen(true)}
            >
              Next
            </ButtonMain>
          </div>
        </div>
      )}

      {step === 3 && (
        <div className="buyNow__purchase">
          <img src={backIcon} alt="back" onClick={() => setStep(1)} />

          <div className="buyNow__purchase__main">
            <h3>Proceed To Input Amount to be Liquidated</h3>

            <Select
              name="Cryptocurrencies"
              label="Select Ultainfinity Cryptocurrencies"
            >
              <option value="ULIT">ULIT</option>
            </Select>
            <Input
              label="Currency"
              //   value={Currency}
              //   onChange={(e) => setCurrency(e.target.value)}
              name="Currency"
              holder="Currency"
              type="number"
            />

            <div className="buyNow__purchase__main__avail">
              MCAP Available Balance: <span>0.38462732</span>{" "}
            </div>

            <div className="getLoan__inputText">
              10,000 <span>USD</span>{" "}
            </div>

            <ButtonMain
              style={{ marginTop: 50 }}
              $fill
              width="100%"
              height="56px"
              radius="4px"
              onClick={() => setModalIsOpen2(true)}
            >
              Next
            </ButtonMain>
          </div>
        </div>
      )}
    </div>
  );
};

export default BuyNow;
