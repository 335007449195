import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../suresystem/css/suresystem.module.scss";
import StepThreeIcon from "../../../assets/surestepthreeicon.svg";
import OtpInput from "react-otp-input";
import { GET_SERVICE } from "../../../shared/services/backend";
import { ToastContainer, toast } from "react-toastify";
import ReactLoading from "react-loading";
import "react-toastify/dist/ReactToastify.css";
const SureStepThree = () => {
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const gotoSureStepFour = async () => {
    setLoading(true);
    const endpoint = `/sure-system/user/repayment/verify-sa-auth-code/${otp}`;
    try {
      const response = await GET_SERVICE(endpoint);
      setLoading(false);
      if (response.data.code === 200 && response.data.status === true) {
        toast.success(response.data.message);
        navigate("/dashboard/get-loan/loans/step-four");
      } else {
        toast.error(response.data.message);
      }
    } catch (e) {
      toast.error(`Network error, Kindly check internet connections`);
    }
  };

  return (
    <div className={styles.main}>
      <div className={styles.sure}>SURE SYSTEM</div>
      <div className={styles.parent}>
        <div className={styles.box}>
          <img src={StepThreeIcon} className={styles.stepIcon} alt="horse" />
          <div className={styles.sureTitle}>
            Input Your Super Admin Authentication Code
          </div>
          <div className={styles.Surelist}>
            Enter the 5 digit code sent to you via Messenger in order to
            proceed.
          </div>
          <div>
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              containerStyle={{
                justifyContent: "space-around",
                marginTop: "2.5em",
              }}
              inputStyle={{
                width: "60px",
                height: "60px",
                background: "none",
                border: "1px solid #9475d4",
                borderRadius: "7px",
                color: "#9475d4",
                fontSize: "2em",
              }}
              renderSeparator={<span></span>}
              renderInput={(props) => <input {...props} />}
            />
            <div className={styles.resend}>Resend Request</div>
          </div>
          <div className={styles.receiptBtnDiv}>
            <button className={styles.btn} onClick={gotoSureStepFour}>
              {loading ? (
                <ReactLoading
                  color="white"
                  width={25}
                  height={25}
                  type="spin"
                />
              ) : (
                "Next"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SureStepThree;
