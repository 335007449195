import { useState } from "react";
import "./verify.scss";
import dasaLogo from "../../assets/dasaLogo.png";
import checkIcon from "../../assets/check.svg";
import SocialLinks from "../socialLinks/SocialLinks";
// import { Link } from "react-router-dom";
import { InputPin } from "../StyledComponent";
import faceIdIcon from "../../assets/faceId.svg";
import fingerPrintIcon from "../../assets/fingerPrint.svg";
import ReactLoading from "react-loading";
import axios from "axios";
import { toast } from "react-toastify";
import { OTCTitle } from "../quickSell/QuickSell";

const Verify = ({ setStepMain, sellOption }) => {
  const [step, setStep] = useState(1);
  const [otp, setOtp] = useState(1);
  const [bioloading, setBioLoading] = useState(false);

  console.log("otp", otp);
  const handleBiometric = async (challenge, challengeStr) => {
    try {
      const credentialOptions = {
        challenge,
        timeout: 60000,
        rpId: window.location.hostname,
        userVerification: "required",
        // allowCredentials: [
        //   {
        //     id: randomId,
        //     type: "public-key",
        //     authenticatorSelection: {
        //       authenticatorAttachment: "platform",
        //       userVerification: "required",
        //     },
        //   },
        // ],
        authenticatorSelection: {
          authenticatorAttachment: "platform",
          userVerification: "required",
          authenticatorExtensions: {
            "ultainfinity/fingerprint": true,
            "ultainfinity/faceid": true,
          },
        },
        extensions: {
          "ultainfinity/fingerprint": {
            sensorType: "fingerprint",
          },
          "ultainfinity/faceid": {
            sensorType: "faceid",
          },
        },
      };
      const assertion = await navigator.credentials.get({
        publicKey: credentialOptions,
      });
      if (typeof assertion?.id === "string") {
        // setBiometricResultId(assertion?.id);

        const data = {
          biometrics_id: assertion?.id,
          challenge_id: challengeStr,
        };

        handleLoginBiometric(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleGenerateChallange = async () => {
    setBioLoading(true);

    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + `/users/biometrics/generate-challenge`
      );
      setBioLoading(false);
      const challenge = Uint8Array.from(atob(response.data?.challenge), (c) =>
        c.charCodeAt(0)
      );
      // const id = Uint8Array.from(atob(response.data?.random_id), (c) =>
      //   c.charCodeAt(0)
      // );

      handleBiometric(challenge, response.data?.challenge);
    } catch (err) {
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      setBioLoading(false);
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleLoginBiometric = async (data) => {
    setBioLoading(true);

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/users/biometrics/authenticate",
        data
      );

      if (response.data.token) {
        const data = {
          user: response.data.data,
          token: response.data.token,
        };
        sessionStorage.setItem("userUlta", JSON.stringify(data));

        setBioLoading(false);
        // toast.success("Successfully Login", {
        //   position: toast.POSITION.TOP_RIGHT,
        // });

        setStep(5);
      }
    } catch (err) {
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setBioLoading(false);
    }
  };
  return (
    <div className="verify">
      {sellOption === "dasa" ? (
        <div className="verify__logo">
          <img src={dasaLogo} alt="" />
        </div>
      ) : (
        <OTCTitle />
      )}

      <div className="verify__main">
        {step === 1 && (
          <>
            <img src={checkIcon} alt="" />
            <h3>Verify It’s You</h3>
            <p>
              Proceed to verify that this is you. We will send you a 5 digit
              code via Messenger your choose.
            </p>
            <span onClick={() => setStep(4)} className="verify__main__bio">
              Verify With Biometrics Instead
            </span>
            <div className="verify__main__btns">
              <span>Cancel Application</span>
              <button onClick={() => setStep(2)} style={{ width: "150px" }}>
                Continue
              </button>
            </div>
          </>
        )}
        {step === 2 && (
          <div className="login__otp">
            <h2 className="login__otp__title">Complete OneStep Verification</h2>
            <p className="login__otp__disc">
              Complete the Onestep verification to proceed. it is important for
              account verification
            </p>

            <div className="login__otp__messanger">
              <p>Kindly select a messenger</p>
              <div className="login__otp__socialLinksCon">
                <div onClick={() => setStep(3)}>
                  <SocialLinks />
                </div>

                {/* <div className="getHelp__main">
                  <p className="getHelp__disc">
                    Having trouble using OneStep Verification?{" "}
                  </p>
                  <span
                    // onClick={() => setCurrAuth("help")}
                    className="getHelp__btn"
                  >
                    Help Centre
                  </span>
                </div> */}
              </div>
            </div>
          </div>
        )}
        {step === 3 && (
          <div className="login__verify">
            <h2 className="login__verify__title">Verify It’s You</h2>
            <p className="login__verify__subTitle">
              Enter the 5 digit code sent to you via Messenger to verify your
              payment
            </p>

            <InputPin onChange={(e) => setOtp(e.target.value)} type="text" />
            <div className="verify__main__btns">
              <span>Cancel Application</span>
              <button onClick={() => setStep(5)} style={{ width: "150px" }}>
                Continue
              </button>
            </div>
          </div>
        )}
        {step === 4 && (
          <div>
            <h2 className="login__verify__title">Verify It’s You</h2>

            <div className="login__otp__choice">Use Biometrics to Verify</div>
            <div className="login__otp__biometric quick">
              <div
                onClick={handleGenerateChallange}
                className="login__otp__biometric__main"
              >
                <div className="login__otp__biometric__item">
                  <img src={fingerPrintIcon} alt="" />
                  <span>Touch ID</span>
                </div>
                <div className="login__otp__biometric__item">
                  <img src={faceIdIcon} alt="" />
                  <span>Face ID</span>
                </div>
              </div>
              {bioloading && (
                <ReactLoading
                  color={"white"}
                  width={30}
                  height={30}
                  type="spin"
                />
              )}
            </div>
          </div>
        )}
        {step === 5 && (
          <div className="login__verify">
            <img src={checkIcon} alt="" />
            <h2 className="login__verify__title">Verification Successful</h2>

            <button onClick={() => setStepMain(6)} style={{ width: "150px" }}>
              Continue
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Verify;
