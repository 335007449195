import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../suresystem/css/suresystem.module.scss";
import StepOneIcon from "../../../assets/suresteponeicon.svg";
import { POST_SERVICE } from "../../../shared/services/backend";
import { ToastContainer, toast } from "react-toastify";
import ReactLoading from "react-loading";
import "react-toastify/dist/ReactToastify.css";
const SureStepOne = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const gotoSureStepTwo = async () => {
    setLoading(true);
    const endpoint = `/sure-system/user/repayment/request-sa-auth-code`;
    try {
      const response = await POST_SERVICE(endpoint);
      setLoading(false);
      if (response.data.code === 200 && response.data.status === true) {
        toast.success(response.data.message);
        navigate("/dashboard/get-loan/loans/step-two");
      } else {
        toast.error(response.data.message);
      }
    } catch (e) {
      toast.error(`Network error, Kindly check internet connections`);
    }
  };

  return (
    <div className={styles.main}>
      <div className={styles.sure}>SURE SYSTEM</div>
      <div className={styles.parent}>
        <div className={styles.box}>
          <img src={StepOneIcon} className={styles.stepIcon} alt="horse" />
          <div className={styles.sureTitle}>
            Request Super Admin Authentication Code
          </div>
          <div className={styles.Surelist}>
            Click on the Next button to send in a request to the Super Admin for
            an authentication code in order to proceed
          </div>
          <div className={styles.receiptBtnDiv}>
            <button className={styles.btn} onClick={gotoSureStepTwo}>
              {loading ? (
                <ReactLoading
                  color="white"
                  width={25}
                  height={25}
                  type="spin"
                />
              ) : (
                "Next"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SureStepOne;
