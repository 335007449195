// import { isMobile } from "react-device-detect";
import { useEffect,useState } from 'react';

import whatsAppIcon from "../../assets/Whats app.svg";
import messangerIcon from "../../assets/MessangerSL.svg";
import TwitterIcon from "../../assets/twitter-icon.svg";
import telegramIcon from "../../assets/TelegramSL.svg";
import lineIcon from "../../assets/lineSL.svg";
import { POST_SERVICE } from "../../shared/services/backend";
import { ToastContainer, toast } from 'react-toastify';
import ReactLoading from "react-loading";
import { useNavigate } from 'react-router-dom';
import { useSocialMediaContext } from '../../shared/store/services/socialmediaContext';
const SocialLinks = () => {
  const [loading, setLoading] = useState(false);
  const navigate=useNavigate()
  const { updateSelectedSocialMedia } = useSocialMediaContext();

  // const location = useLocation();

  // const links = getRequest("bots/ultainfinity");

  const links = [
    {
      image: telegramIcon,
      title: "telegram",
      href: "https://t.me/" + process.env.REACT_APP_SOCIALS_BOTS_TELEGRAM,
    },
    // {
    //   image: viberIcon,
    //   title: "Viber",
    //   href: "https://viber.com/pa?ChatURI=" +  process.env.REACT_APP_SOCIALS_BOTS_TELEGRAM,
    // },

    {
      image: whatsAppIcon,
      title: "twilio-whatsapp",
      href: "http://wa.me/" + process.env.REACT_APP_SOCIALS_BOTS_WHATSAPP,
    },

    {
      image: messangerIcon,
      title: "facebook-messenger",
      href:
        "https://www.facebook.com/" +
        process.env.REACT_APP_SOCIALS_BOTS_MESSENGER,
    },
    {
      image: lineIcon,
      title: "Line",
      href:
        "https://linevoom.line.me/user/" +
        process.env.REACT_APP_SOCIALS_BOTS_LINE,
    },
    {
      image: TwitterIcon,
      title: "twitter",
      href:null,
    },
  ];

  /**
   * go to Code Confirm Page
   */
  const goToConfirmCodePage = (e) => {

    const channelUrl = e.target.dataset.href;
   
    // sessionStorage.setItem("channelTitle", e.target.alt);
    // sessionStorage.setItem("channelUrl", channelUrl);

    // // Open in new tab target page
  
    window.open(channelUrl, "_blank")?.focus();

    // history.push("/registration/verify-code/id");
  };

  const goToTwitter=async(e, title)=>{
    
    const callback_url =
    process.env.REACT_APP_ENV === "staging"
      ? "https://staging.ulitcoin.com/twitter"
      : "https://ulitcoin.com/twitter";

    sessionStorage.setItem('useTwitter',title)
    updateSelectedSocialMedia("twitter");

    if(title==="twitter"){
      e.stopPropagation();
      setLoading(true);
      let body={
        callback_url:callback_url
    }
    
    try {
      const response = await fetch( `${process.env.REACT_APP_API_URL}/communications/onestep/twitter/user/login`, {
        method: "POST", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
  
      const result = await response.json();
      setLoading(false);
      if (result.code === 200  && result.status === true){
        sessionStorage.setItem('useTwitter',title)
        //toast.success(result.message);
        //navigate('/twitter')
        //window.open(result.data.redirect_to, "_blank")?.focus();
        window.location.href = result.data.redirect_to;
      }
      else {
        setLoading(false);
        toast.error(result.message);
      }
      } catch (error) {
        setLoading(false);
        toast.error(`Network error, Kindly check internet connections`);
    }
  }}

  return (
    <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
      {links.map((v, index) => {
        return (
          <div
            style={{ cursor: "pointer", display: "flex" }}
            key={index}
            onClick={(e) => {
              
              goToConfirmCodePage(e);
              updateSelectedSocialMedia(v.title);
            }}
          >
           <img src={v.image} alt={v.title} data-href={v.href} onClick={(e)=>goToTwitter(e , v.title)} />
             {/*<img src={v.image} alt={v.title} data-href={v.href}  />*/}
          </div>
        );
      })}
    </div>
  );
};

export default SocialLinks;
