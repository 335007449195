import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import SocialLinks from "../socialLinks/SocialLinks";
import { Button, ButtonMain, InputPin, InputUser } from "../StyledComponent";
import profileIcon from "../../assets/Profile.svg";
import ReactLoading from "react-loading";
import "./twitterLogin.module.scss";
import PhoneInput from "react-phone-input-2";
import faceIdIcon from "../../assets/faceId.svg";
import fingerPrintIcon from "../../assets/fingerPrint.svg";
import { useSocialMediaContext } from "../../shared/store/services/socialmediaContext";

const TwitterSignup = ({ setCurrAuth, setAuth }) => {
  const [step, setstep] = useState(2);
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [resendOtp, setResendOtp] = useState(false);
  const [btnDisable, setbtnDisable] = useState(true);
  const [userAuth, setUserAuth] = useState({
    username: "",
    phone: "",
    role: "investor",
    sid: "",
    referral_code: "",
  });
  const [bioloading, setBioLoading] = useState(false);
  const [biometricSupported, setBiometricSupported] = useState(false);
  const [biometricRegisterSuc, setBiometricRegisterSuc] = useState(false);

  const user = JSON.parse(sessionStorage.getItem("userUlta")?? "{}").user;
  const [channel, setChannel] = useState("");
  const [userId, setUserId] = useState("");
  const { updateSelectedSocialMedia } = useSocialMediaContext();

  useEffect(() => {
    // Retrieve value from local storage during component mount
    const storedSocialMedia = localStorage.getItem("selectedSocialMedia");
    if (storedSocialMedia) {
      // Update state with the retrieved value
      console.log("getting a new channel");
      setChannel(storedSocialMedia);
    }
  }, [updateSelectedSocialMedia]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    // Get the value of param1
    const param1Value = urlParams.get("state");
    console.log("param1:", param1Value);
    // Get the value of param2
    const code = urlParams.get("code");
    console.log("code:", code);
    const callback_url =
      process.env.REACT_APP_ENV === "staging"
        ? "https://staging.ulitcoin.com/twitter"
        : "https://ulitcoin.com/twitter";

    const fetchData = async () => {
      if (code) {
        //setLoading(true);
        let body = {
          code: code,
          callback_url: callback_url,
        };

        try {
          const response = await fetch(
            process.env.REACT_APP_API_URL +
              `/communications/onestep/twitter/user/access/token`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(body),
            }
          );

          const result = await response.json();

          if (result.code === 200 && result.status === true) {
            toast.success(result.message);
            setUserId(result.data.user_id);
          } else {
            toast.error(result.message);
          }
        } catch (error) {
          toast.error(`Network error, Kindly check internet connections`);
        }
      }
    };
    fetchData();

    // checkBiometricSupport();
  }, []);

  function checkBiometricSupport() {
    if (
      window.PublicKeyCredential &&
      navigator.credentials &&
      (navigator.credentials.create || navigator.credentials.get)
    ) {
      setBiometricSupported(true);
    } else {
      setBiometricSupported(false);
    }
  }

  const handleVerifyOtp = async () => {
    const code = otp.toUpperCase();
    setLoading(true);

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + `/users/user-account/otp-verify`,
        { code, channel }
      );
      setLoading(false);
      if (response.data.type === "success") {
        if (response.data?.data?.user_status !== "creating") {
          toast.warning("User Already exist, Login", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setCurrAuth("login");
        } else {
          setUserAuth((curr) => {
            return { ...curr, sid: response.data?.data?.sid };
          });
          // setUserStatus(response.data?.data?.user_status);
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setstep(3);
        }
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (err) {
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      setLoading(false);
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleSignup = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/users/user-account/send-userdata",
        userAuth
      );

      if (response.data.data.token) {
        sessionStorage.setItem("userUlta", JSON.stringify(response.data.data));

        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        // navigate("/dashboard");
        setstep(4);
        // sessionStorage.setItem("userUlta", JSON.stringify(response.data.data));
        // setLoading(false);
        // toast.success("Successfully Login", {
        //   position: toast.POSITION.TOP_RIGHT,
        // });

        // setAuth(true);
      }
    } catch (err) {
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      setLoading(false);
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    // setLoading(true);
    // dispatch(signup(userAuth))
    //   .unwrap()
    //   .then(() => {
    //     setLoading(false);
    //     toast.success("Successfully Signed up", {
    //       position: toast.POSITION.TOP_RIGHT,
    //     });

    //     navigate("/signup/biometric");
    //   })
    //   .catch((err) => {
    //     toast.error(err, {
    //       position: toast.POSITION.TOP_RIGHT,
    //     });
    //     setLoading(false);
    //   });
  };

  const handleBiometric = async (challenge, id, challengeStr) => {
    try {
      const credentialOptions = {
        rp: {
          name: "Ultainfinity",
          id: window.location.hostname,
        },
        user: {
          displayName: user?.user?.display_name
            ? user?.user?.display_name
            : "Ultainfinity User",
          id,
          name: user?.user?.display_name
            ? user?.user?.display_name
            : "Ultainfinity User",
        },
        challenge,
        pubKeyCredParams: [
          {
            type: "public-key",
            alg: -7,
          },
        ],
        timeout: 1800000,
        attestation: "direct",
        authenticatorSelection: {
          userVerification: "required",
          authenticatorAttachment: "platform",
          requireResidentKey: true,
          residentKey: "required",
          authenticatorTransport: ["usb", "nfc", "ble"],
          extensions: {
            "ultainfinity:biometric-authentication": {
              supportedModes: ["fingerprint", "face"],
            },
          },
        },
        // authenticatorSelection: {
        //   residentKey: "required",
        //   userVerification: "required",
        //   authenticatorAttachment: "platform",
        // },
      };
      const credential = await navigator.credentials.create({
        publicKey: credentialOptions,
      });

      // console.log(credential);
      if (typeof credential?.id === "string") {
        // setBiometricRegisterSuc(credential?.id);
        const data = {
          // sid: user.id,
          // challenge_id: challengeStr,
          biometrics_id: credential?.id,
        };
        handleRegisterBiometric(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleRegisterBiometric = async (data) => {
    setBioLoading(true);

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/users/biometrics/store-id",
        data,
        {
          headers: {
            Authorization: "Bearer " + user.accessToken,
            "user-id": user?.id,
          },
        }
      );

      setBioLoading(false);
      toast.success("Successfully Registered Biometrics", {
        position: toast.POSITION.TOP_RIGHT,
      });

      setBiometricRegisterSuc(true);
      // setAuth(true);
    } catch (err) {
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      setBioLoading(false);
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    // dispatch(registerBiometric(data))
    //   .unwrap()
    //   .then(() => {
    //     setBioLoading(false);
    //     toast.success("Successfully Registered Biometrics", {
    //       position: toast.POSITION.TOP_RIGHT,
    //     });

    //     setBiometricRegisterSuc(true);
    //   })
    //   .catch((err) => {
    //     toast.error(err, {
    //       position: toast.POSITION.TOP_RIGHT,
    //     });
    //     setBioLoading(false);
    //   });
  };

  const handleGenerateChallange = async () => {
    setBioLoading(true);

    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + `/users/biometrics/generate-challenge`
      );
      setBioLoading(false);
      const challenge = Uint8Array.from(atob(response.data?.challenge), (c) =>
        c.charCodeAt(0)
      );
      const id = Uint8Array.from(atob(response.data?.random_id), (c) =>
        c.charCodeAt(0)
      );

      handleBiometric(challenge, id, response.data?.challenge);
    } catch (err) {
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      setBioLoading(false);
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleClick = () => {
    if (step === 2) {
      handleVerifyOtp();
      // setstep(3);
    }
    if (step === 3) {
      handleSignup();
      // setstep(4);
      // navigate("/signup/biometric");
    }
  };

  useEffect(() => {
    checkBiometricSupport();
  }, []);

  useEffect(() => {
    if (otp && otp.length === 6) {
      setbtnDisable(false);
    } else {
      setbtnDisable(true);
    }
  }, [otp]);

  useEffect(() => {
    if (userAuth.username.length > 7 && userAuth.phone.length > 4) {
      setbtnDisable(false);
    } else {
      setbtnDisable(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAuth]);

  console.log("user", user);

  //function to resend otp
  const handleGetNewToken = async () => {
    setResendOtp(true);
    const apiUrl = process.env.REACT_APP_API_URL;
    try {
      const response = await fetch(
        `${apiUrl}/communications/onestep/twitter/user/access/token/resend`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: userId,
          }),
        }
      );

      if (response.ok) {
        const resData = await response.json();
        setResendOtp(false);
        // window.open( resData.data.redirect_to, '_blank')
        //window.location.href = resData.data.redirect_to;
        toast.success(resData?.message);
        console.log("otp resend");
      } else {
        const resData = await response.json();
        setResendOtp(false);
        console.log("resending otp error");
        toast.error(resData?.message);
      }
    } catch (error) {
      setResendOtp(false);
      console.log("resending otp error-- internet");
    }
  };

  return (
    <div className="login">
      {step === 1 && (
        <div className="login__otp">
          <h2 className="login__otp__title">Complete Onestep Verification</h2>
          <p className="login__otp__disc">
            Complete the Onestep verification to proceed. it is important for
            account verification
          </p>
          <h3 style={{ marginBottom: 20 }}>SIGN UP</h3>

          {/* <div className="login__otp__choice">
        Select a messenger of your choice
      </div> */}

          <div className="login__otp__messanger">
            <p>Kindly select a messenger</p>
            <div className="login__otp__socialLinksCon">
              <div onClick={() => setstep(2)}>
                <SocialLinks />
              </div>
            </div>
            <div className="getHelp__main">
              <p className="getHelp__disc">
                Having trouble using OneStep Verification?
              </p>
              <span
                onClick={() => setCurrAuth("help")}
                className="getHelp__btn"
              >
                Help Centre
              </span>
            </div>
          </div>
        </div>
      )}

      {step === 2 && (
        <div className="login__verify">
          <h2 className="login__verify__title">OTP Verification</h2>
          <p className="login__verify__subTitle">
            Complete the Onestep verification to proceed. it is important for
            account verification
          </p>

          <p className="login__verify__disc">
            Enter the OTP verification code received in your messenger. 2 Factor
            Authentications are essential identification and verification
            measures, that OneStep ID by Ultainfinity uses to protect you and
            your account 24/7.
          </p>
          <div style={{ cursor: "pointer" }} onClick={handleGetNewToken}>
            {resendOtp ? "loading..." : "Resend Code"}
          </div>
          <br />

          <InputPin onChange={(e) => setOtp(e.target.value)} type="text" />
        </div>
      )}

      {step === 3 && (
        <div className="login__user">
          <h2 className="login__user__title">Username & Phone Number</h2>
          <p className="login__user__disc">
            Complete the Onestep verification to proceed. it is important for
            account verification
          </p>
          <div className="login__user__biometric">
            <p className="login__user__disc">
              Create Username and Enter Phone Number
            </p>
            <div className="login__user__input">
              <div className="login__user__input__icon">
                <img src={profileIcon} alt="profile icon" />
              </div>
              <InputUser
                onChange={(e) =>
                  setUserAuth((curr) => {
                    return { ...curr, username: e.target.value };
                  })
                }
                type="text"
                placeholder="Enter Username"
              />
              <span style={{ fontSize: 12, position: "relative", top: -5 }}>
                Minimum of 8 character
              </span>
              <PhoneInput
                country={"us"}
                value={userAuth.phone}
                onChange={(phone) => setUserAuth({ ...userAuth, phone: phone })}
              />
            </div>
            <div style={{ marginTop: 20 }}>
              <Button
                disabled={btnDisable}
                $disable={btnDisable}
                onClick={handleClick}
                width="250px"
                $fill
              >
                {loading ? (
                  <ReactLoading
                    color="white"
                    width={30}
                    height={30}
                    type="spin"
                  />
                ) : (
                  "Confirm OneStep ID"
                )}
              </Button>
            </div>
          </div>

          {/* <span
        className="login__user__biometric"
        onClick={() => setModalIsOpen(true)}
      >
        Register Biometrics For Easy Login & Account Security
      </span> */}
        </div>
      )}

      {step === 4 && (
        <div className="login__otp">
          <h2 className="login__otp__title">Biometrics Registration</h2>
          <p className="login__otp__easy">
            <b>REGISTER YOUR BIOMETRICS FOR FASTER LOGIN, DO IT NOW OR LATER</b>
            <br />
          </p>
          <div className="login__otp__biometric">
            <p style={{ textAlign: "center" }}>
              Register with Biometrics: Touch ID and Face ID for enhanced Login
              and Account Security. Enabling your Biometrics will help you to
              unlock a better experience across Ultainfinity.
            </p>
            <div className="login__otp__biometric__main">
              <div className="login__otp__biometric__item">
                <img src={fingerPrintIcon} alt="" />
                <span>Touch ID</span>
              </div>
              <div className="login__otp__biometric__item">
                <img src={faceIdIcon} alt="" />
                <span>Face ID</span>
              </div>
            </div>
            <div className="getHelp__main">
              {!biometricSupported ? (
                <span className="login__biom__biometric__err">
                  Biometrics is not supported on this device
                </span>
              ) : biometricRegisterSuc ? (
                <Button width="250px" $fill onClick={() => setAuth(true)}>
                  Go to Dashboard
                </Button>
              ) : (
                <ButtonMain
                  onClick={handleGenerateChallange}
                  $fill
                  width="280px"
                >
                  {bioloading ? (
                    <ReactLoading
                      color={"white"}
                      width={20}
                      height={20}
                      type="spin"
                    />
                  ) : (
                    "Register Biometrics"
                  )}
                </ButtonMain>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="login__btnCon">
        {step === 2 && (
          <Button
            disabled={btnDisable}
            $disable={btnDisable}
            onClick={handleClick}
            width="250px"
            $fill
          >
            {loading ? (
              <ReactLoading color="white" width={30} height={30} type="spin" />
            ) : (
              "Continue"
            )}
          </Button>
        )}
      </div>

      {step !== 4 ? (
        <>
          <p className="login__terms">
            <b
              style={{
                fontSize: 16,
                marginBottom: 10,
                display: "inline-block",
              }}
            >
              Already Registered for OneStep?
            </b>
            <br />
            If you’ve previously registered as a OneStep User on another
            Ultainfinity Plattform, then you simply need to proceed to Login
            page. You do not need to Sign up again for OneStep on this
            Ultainfinity’ Platform.
            <br />
            {/* <span onClick={() => setCurrAuth("login")}>Login</span> */}
          </p>
        </>
      ) : (
        <p className="login__skip" onClick={() => setAuth(true)}>
          Skip to Dashboard
        </p>
      )}

      <p className="login__terms">
        By Signing Up you agree to our{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://ultainfinitywealthlaunchpad.com/privacy"
        >
          Terms & Privacy Policy.
        </a>{" "}
      </p>
    </div>
  );
};

export default TwitterSignup;
