import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

export default function useLoanRepayment(setStep, setModalIsOpen) {
  const [level, setLevel] = useState(1);
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [btnDisable, setbtnDisable] = useState(true);

  const [userAuth, setUserAuth] = useState({
    username: "",
    sid: "",
  });

  useEffect(() => {
    if (otp?.length === 6) {
      setbtnDisable(false);
    } else {
      setbtnDisable(true);
    }
  }, [otp]);
  const handleVerifyOtp = async () => {
    const code = otp.toUpperCase();
    setLoading(true);

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + `/users/user-account/otp-verify`,
        { code }
      );
      setLoading(false);
      if (response.data.type === "success") {
        setStep(5);
        setLevel(1);
        setModalIsOpen(false);
        if (response.data?.data?.user_status === "active") {
          setUserAuth((curr) => {
            return { ...curr, sid: response.data?.data?.sid };
          });
          // console.log("response.data.data", response.data.data);

          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (response.data?.data?.user_status === "creating") {
          toast.warning("User Do Not exist, Signup", {
            position: toast.POSITION.TOP_RIGHT,
          });
          // navigate("/signup");
        } else {
          // dispatch(loginToken(response.data.data));
          sessionStorage.setItem(
            "userUlta",
            JSON.stringify(response.data.data)
          );

          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          // navigate("/dashboard");
          //   setAuth(true);
        }
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (err) {
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      setLoading(false);
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleClick = () => {
    if (level === 2) {
      handleVerifyOtp();
    }
  };

  return {
    level,
    loading,
    handleClick,
    handleVerifyOtp,
    setLevel,
    btnDisable,
    setOtp,
  };
}
