import { useEffect, useState } from "react";
import ReactLoading from "react-loading";
// import wallet from "../../assets/wallet.png";
import styles from "./quickBuy.module.scss";
import { ErrorMessage } from "../career/Career";
import solana from "../../assets/solana.png";
import coinbase from "../../assets/coinbase.png";
import ulit from "../../assets/ulit.png";
import downloadIcon from "../../assets/download.svg";
import backIcon from "../../assets/back.svg";
import Login from "../auth/Login";
import Signup from "../auth/Signup";
import HelpCentre from "../helpCenter/HelpCentre";
import WalletConnect from "../walletConnect/WalletConnect";
import axios from "axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useDisconnect } from "wagmi";
import { Button, ButtonMain } from "../StyledComponent";
import faceIdIcon from "../../assets/faceId.svg";
import fingerPrintIcon from "../../assets/fingerPrint.svg";

export const Input = ({ inputError, name, holder, value, onChange, type }) => {
  return (
    <div className={styles.quickBuy__right__input}>
      <input
        type={type ? type : "text"}
        id={name}
        onChange={onChange}
        value={value}
        placeholder={holder}
      />
      <br />
      {inputError && <ErrorMessage inputError={inputError} />}
    </div>
  );
};

export const Select = ({
  inputError,
  name,
  holder,
  value,
  onChange,
  children,
}) => {
  return (
    <div className={styles.quickBuy__right__select}>
      <select value={value} id={name} onChange={onChange}>
        <option value="">{holder}</option>
        {children}
      </select>
      <br />
      {inputError && <ErrorMessage inputError={inputError} />}
    </div>
  );
};
const QuickBuy = ({ dashboard }) => {
  const user = JSON.parse(sessionStorage.getItem("userUlta") ?? "{}")?.user;

  const [step, setStep] = useState(1);
  // const [address, setAddress] = useState("");
  const [currAuth, setCurrAuth] = useState("login");
  const [amount, setAmount] = useState("");
  const [disconnect, setDisconnect] = useState(false);
  const [loading, setLoading] = useState("");
  const [showButton, setShowButton] = useState("");
  const [paymentAmount, setPaymentAmount] = useState("");
  const [paymentCrypto, setPaymentCrypto] = useState("");
  const [document, setDocument] = useState("");
  const [crypto, setCrypto] = useState("ULIT");
  const [paymentMethod, setPaymentMethod] = useState("Coinbase Commerce");
  const [auth, setAuth] = useState(user.token ? true : false);
  const [data, setData] = useState({
    address: "",
    network: "",
    wallet: "",
    walletId: "",
    img: "",
  });
  const [bioloading, setBioLoading] = useState(false);
  const [biometricSupported, setBiometricSupported] = useState(false);
  const [biometricRegisterSuc, setBiometricRegisterSuc] = useState(false);

  function checkBiometricSupport() {
    if (
      window.PublicKeyCredential &&
      navigator.credentials &&
      (navigator.credentials.create || navigator.credentials.get)
    ) {
      setBiometricSupported(true);
    } else {
      setBiometricSupported(false);
    }
  }

  var options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  const date = new Date();

  const confirm = false;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // console.log("data", data);

  const handleInitiatePayment = async () => {
    const authHeader = {
      Authorization: "Bearer " + user.accessToken,
      "user-id": user?.id,
    };
    setLoading(true);
    try {
      const res = await axios.post(
        process.env.REACT_APP_API_URL + `/launchpad/app/token-calculate`,
        {
          product: "969ff58b-5d48-4de4-8e9e-cb6bb39e6041",
          investment_amount: parseInt(amount),
          currency: paymentCrypto,
          platform: "uab",
        },
        { headers: authHeader }
      );

      // console.log("res", res);
      setLoading(false);
      setPaymentAmount(res.data?.data?.payment_amount);
      setStep(4);
    } catch (err) {
      setLoading(false);
      const message =
        (err.res && err.res.data && err.res.data.message) ||
        err.message ||
        err.toString();
      // console.log(error.response);
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handlePreOrder = async () => {
    setLoading(true);
    setShowButton(false);

    const authHeader = {
      Authorization: "Bearer " + user.accessToken,
      "user-id": user?.id,
    };

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/launchpad/app/deposits`,
        {
          amount: parseFloat(amount),
          asset: "ULIT",
          wallet_id: data.walletId,
          currency: "usd",
        },
        { headers: authHeader }
      );
      console.log("res", res);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/payments/app/orders/charge`,
          {
            gateway:
              paymentMethod === "Coinbase Commerce"
                ? "coinbase-commerce"
                : "network-solana",
            amount: parseFloat(paymentAmount),
            currency: paymentCrypto,
            ridrect_url: "",
            cancel_url: "",
            document: res.data.data.document,
          },
          { headers: authHeader }
        );

        setLoading(false);
        setShowButton(true);
        setDocument(response.data.data);
        setStep(4);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        toast.error(message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      console.log(error.response);
      setLoading(false);
    }
  };

  const handleBiometric = async (challenge, id, challengeStr) => {
    try {
      const credentialOptions = {
        rp: {
          name: "Ultainfinity",
          id: window.location.hostname,
        },
        user: {
          displayName: user?.user?.display_name
            ? user?.user?.display_name
            : "Ultainfinity User",
          id,
          name: user?.user?.display_name
            ? user?.user?.display_name
            : "Ultainfinity User",
        },
        challenge,
        pubKeyCredParams: [
          {
            type: "public-key",
            alg: -7,
          },
        ],
        timeout: 1800000,
        attestation: "direct",
        authenticatorSelection: {
          userVerification: "required",
          authenticatorAttachment: "platform",
          requireResidentKey: true,
          residentKey: "required",
          authenticatorTransport: ["usb", "nfc", "ble"],
          extensions: {
            "ultainfinity:biometric-authentication": {
              supportedModes: ["fingerprint", "face"],
            },
          },
        },
        // authenticatorSelection: {
        //   residentKey: "required",
        //   userVerification: "required",
        //   authenticatorAttachment: "platform",
        // },
      };
      const credential = await navigator.credentials.create({
        publicKey: credentialOptions,
      });

      // console.log(credential);
      if (typeof credential?.id === "string") {
        // setBiometricRegisterSuc(credential?.id);
        const data = {
          // sid: user.id,
          // challenge_id: challengeStr,
          biometrics_id: credential?.id,
        };
        handleRegisterBiometric(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleRegisterBiometric = async (data) => {
    setBioLoading(true);

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/users/biometrics/store-id",
        data,
        {
          headers: {
            Authorization: "Bearer " + user.accessToken,
            "user-id": user?.id,
          },
        }
      );

      setBioLoading(false);
      toast.success("Successfully Registered Biometrics", {
        position: toast.POSITION.TOP_RIGHT,
      });

      setBiometricRegisterSuc(true);
      // setAuth(true);
    } catch (err) {
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      setBioLoading(false);
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    // dispatch(registerBiometric(data))
    //   .unwrap()
    //   .then(() => {
    //     setBioLoading(false);
    //     toast.success("Successfully Registered Biometrics", {
    //       position: toast.POSITION.TOP_RIGHT,
    //     });

    //     setBiometricRegisterSuc(true);
    //   })
    //   .catch((err) => {
    //     toast.error(err, {
    //       position: toast.POSITION.TOP_RIGHT,
    //     });
    //     setBioLoading(false);
    //   });
  };

  const handleGenerateChallange = async () => {
    setBioLoading(true);

    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + `/users/biometrics/generate-challenge`
      );
      setBioLoading(false);
      const challenge = Uint8Array.from(atob(response.data?.challenge), (c) =>
        c.charCodeAt(0)
      );
      const id = Uint8Array.from(atob(response.data?.random_id), (c) =>
        c.charCodeAt(0)
      );

      handleBiometric(challenge, id, response.data?.challenge);
    } catch (err) {
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      setBioLoading(false);
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleLogout = () => {
    sessionStorage.removeItem("userUlta");
    setAuth(false);
    setStep(1);
  };
  const disconnectCoinBase = useDisconnect().disconnect;
  const handleDisconnect = () => {
    disconnectCoinBase();
    setData({
      address: "",
      network: "",
      wallet: "",
      walletId: "",
      img: "",
    });
    setStep(1);
    setDisconnect(true);
  };
  useEffect(() => {
    checkBiometricSupport();
  }, []);
  console.log("amount", amount);

  return (
    <div className={styles.quickBuy}>
      <div className={styles.quickBuy__left}></div>
      <div
        style={{ background: auth ? "inherit" : "#161322" }}
        className={styles.quickBuy__right}
      >
        {step >= 1 && auth && step !== 7 && (
          <div className={styles.quickBuy__right__logoutBtn}>
            <div className={styles.quickBuy__right__logoutBtn__back}>
              {step >= 3 && (
                <img onClick={() => setStep(step - 1)} src={backIcon} alt="" />
              )}
            </div>

            <div className={styles.quickBuy__right__logoutBtn__main}>
              {step >= 2 && (
                <button onClick={handleDisconnect}>Disconnect Wallet</button>
              )}
              {!dashboard && <span onClick={handleLogout}>Logout</span>}
              {!dashboard && (
                <button style={{ width: 230 }} onClick={() => setStep(7)}>
                  Register Biometrics
                </button>
              )}
            </div>
          </div>
        )}
        {step === 7 && (
          <div className={styles.quickBuy__right__logoutBtn}>
            <div className={styles.quickBuy__right__logoutBtn__back}>
              <img
                onClick={() => {
                  setStep(1);
                  setBiometricRegisterSuc(false);
                }}
                src={backIcon}
                alt=""
              />
            </div>
          </div>
        )}
        {step >= 2 && auth && !dashboard && step !== 7 && (
          <div className={styles.quickBuy__right__net}>
            <p>{data.network}</p>
            <p>
              <img src={ulit} alt="" /> {data.address.substring(0, 10)}....
            </p>
          </div>
        )}

        {!auth ? (
          <>
            <div className={styles.quickBuy__right__authBtn}>
              <button onClick={() => setCurrAuth("signup")}>Sign Up</button>
              <button onClick={() => setCurrAuth("login")}>
                <Link
                  to="/quick-buy"
                  style={{ textDecoration: "none", color: "#d9d9d9" }}
                >
                  Login
                </Link>
              </button>
            </div>
            {currAuth === "login" ? (
              <Login setAuth={setAuth} setCurrAuth={setCurrAuth} />
            ) : currAuth === "signup" ? (
              <Signup setAuth={setAuth} setCurrAuth={setCurrAuth} />
            ) : (
              <HelpCentre />
            )}
          </>
        ) : (
          <>
            {step === 1 && (
              <WalletConnect
                disconnect={disconnect}
                setStep={setStep}
                data={data}
                setData={setData}
              />
            )}

            {/* {step === 2 && (
              <div className={styles.quickBuy__right__connect}>
                
              </div>
            )} */}

            {/* {step === 3 && (
              <div className={styles.quickBuy__right__connected}>
                
              </div>
            )} */}
            {step === 2 && (
              <div className={styles.quickBuy__right__promo}>
                <h2>Partner Promo Code</h2>
                <p>
                  Enter the code you got from a Partner who will earn for
                  referring you.
                </p>
                <input type="text" name="promo" id="promo" />
                <button
                  className={styles.quickBuy__btn}
                  onClick={() => setStep(3)}
                >
                  Next
                </button>
              </div>
            )}
            {step === 3 && (
              <div className={styles.quickBuy__right__purchase}>
                <h2>Make Payment To Purchase</h2>
                <Select
                  name="Cryptocurrency"
                  value={crypto}
                  holder="Select Cryptocurrency"
                  onChange={(e) => setCrypto(e.target.value)}
                >
                  <option value="ULIT">ULIT</option>
                </Select>
                <Select
                  onChange={(e) => setPaymentCrypto(e.target.value)}
                  name="Payment"
                  holder="Select Payment Currency"
                  value={paymentCrypto}
                >
                  <option value="usdt">USDT</option>
                  <option value="usdc">USDC</option>
                  <option value="btc">BTC</option>
                  <option value="eth">ETH</option>
                  <option value="ltc">LTC</option>
                  <option value="doge">DOGE</option>
                  {/* <option value="BNB">BNB</option> */}
                </Select>

                <Input
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  name="amount"
                  holder="Amount"
                  type="number"
                />
                <div className={styles.quickBuy__right__purchase__inputText}>
                  ULIT (TBA) | ULIT
                </div>
                <h3>Select Payment Method</h3>

                <div
                  style={{
                    background:
                      paymentMethod === "Coinbase Commerce"
                        ? "linear-gradient(132deg, #5158F6 0%, #822DF5 52.60%, #F3A761 100%)"
                        : "transparent",
                    // border: "none",
                  }}
                  onClick={() => setPaymentMethod("Coinbase Commerce")}
                  className={styles.quickBuy__right__purchase__method}
                >
                  <img src={coinbase} alt="" /> Pay with Coinbase Commerce
                </div>
                <div
                  style={{
                    background:
                      paymentMethod === "Solana Pay"
                        ? "linear-gradient(132deg, #5158F6 0%, #822DF5 52.60%, #F3A761 100%)"
                        : "transparent",
                    // border: "none",
                  }}
                  onClick={() => setPaymentMethod("Solana Pay")}
                  className={styles.quickBuy__right__purchase__method}
                >
                  <img src={solana} alt="" /> Pay with Solana Pay
                </div>

                <div style={{ marginTop: 40 }}>
                  <button
                    className={styles.quickBuy__btn}
                    // onClick={handlePreOrder}
                    onClick={handleInitiatePayment}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {loading ? (
                      <ReactLoading
                        color="white"
                        width={30}
                        height={30}
                        type="spin"
                      />
                    ) : (
                      "Next"
                    )}
                  </button>
                </div>
              </div>
            )}

            {step === 4 && (
              <div className={styles.quickBuy__right__comfirm}>
                <h3>INVOICE</h3>
                <h4>You are about to purchase ULIT (${amount})</h4>
                <div className={styles.quickBuy__right__comfirm__main}>
                  <p>Payment details</p>
                  <div className={styles.quickBuy__right__comfirm__main__item}>
                    Payment Method <span>{paymentMethod}</span>
                  </div>
                  <div className={styles.quickBuy__right__comfirm__main__item}>
                    Total amount{" "}
                    <span>
                      ${amount} ({paymentAmount}{" "}
                      <span style={{ textTransform: "uppercase" }}>
                        {paymentCrypto}
                      </span>
                      )
                    </span>
                  </div>
                  <div className={styles.quickBuy__right__comfirm__main__item}>
                    Time{" "}
                    <span>{date.toLocaleDateString("en-US", options)}</span>
                  </div>
                  <div className={styles.quickBuy__right__comfirm__main__item}>
                    Paid Invoice confirmation for{" "}
                    <span>{user.user?.display_name}</span>
                  </div>
                  {/* <div className={styles.quickBuy__right__comfirm__main__item}>
                    Invoice number <span>001</span>
                  </div> */}
                  <p>
                    Transaction ID <span>065468748942</span>
                  </p>
                </div>

                {!showButton ? (
                  <button
                    className={styles.quickBuy__btn}
                    onClick={handlePreOrder}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {loading ? (
                      <ReactLoading
                        color="white"
                        width={30}
                        height={30}
                        type="spin"
                      />
                    ) : (
                      "Continue to Payment"
                    )}
                  </button>
                ) : paymentMethod === "Coinbase Commerce" ? (
                  <a
                    // style={{ width: "100%" }}
                    href={document.payment_order_url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button
                      className={styles.quickBuy__btn}
                      onClick={() => setStep(5)}
                    >
                      Proceed to Payment
                    </button>
                  </a>
                ) : (
                  <a
                    // onClick={handlePayment}
                    // style={{ width: "100%" }}
                    href={`https://solanapay.ultainfinity.com/pending?amount=${paymentAmount}&label=Ultainfinity+Launchpad&recipient=${document.recipient_address}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button
                      className={styles.quickBuy__btn}
                      // onClick={() => setStep(5)}
                    >
                      Proceed to Payment
                    </button>
                  </a>
                )}
              </div>
            )}
            {step === 5 && (
              <div className={styles.quickBuy__right__comfirm}>
                <h3>Congrats!</h3>
                <h4>
                  Your payment is being processed and You successfully paid a
                  deposit of ULIT (${amount})
                </h4>
                <div className={styles.quickBuy__right__comfirm__main}>
                  <p>Payment details</p>
                  <div className={styles.quickBuy__right__comfirm__main__item}>
                    Payment Method <span>{paymentMethod}</span>
                  </div>
                  <div className={styles.quickBuy__right__comfirm__main__item}>
                    Total amount{" "}
                    <span>
                      ${amount} ({amount}{" "}
                      <span style={{ textTransform: "uppercase" }}>
                        {paymentCrypto}
                      </span>
                      )
                    </span>
                  </div>

                  <div className={styles.quickBuy__right__comfirm__main__item}>
                    To Receive <span>ULIT COIN (TBA)</span>
                  </div>
                  <div className={styles.quickBuy__right__comfirm__main__item}>
                    Time{" "}
                    <span>{date.toLocaleDateString("en-US", options)}</span>
                  </div>
                  <div className={styles.quickBuy__right__comfirm__main__item}>
                    Paid Invoice confirmation for{" "}
                    <span>{user.user?.display_name}</span>
                  </div>
                  {/* <div className={styles.quickBuy__right__comfirm__main__item}>
                    Invoice number <span>001</span>
                  </div> */}
                  <p>
                    Transaction ID <span>065468748942</span>
                  </p>
                </div>

                <div className={styles.quickBuy__right__comfirm__btnCon}>
                  {/* <div
                    className={
                      styles.quickBuy__right__comfirm__btnCon__download
                    }
                  >
                    <img src={downloadIcon} alt="" /> Download Receipt PDF
                  </div> */}
                  <button
                    className={styles.quickBuy__btn}
                    onClick={() => setStep(6)}
                  >
                    Continue
                  </button>
                </div>
              </div>
            )}

            {step === 6 && (
              <>
                {!confirm ? (
                  <div className={styles.quickBuy__right__await}>
                    <h2>Awaiting Delivery Confirmation</h2>
                    <p>
                      Your deposit has been sent and is currently under review
                      and checks. Once verified, you will see your delivery
                      confirmation here, once your purchased token has been
                      released to you after the receipt of your deposit has been
                      confirmed by the Admin. This may take few hours and up to
                      3 days. Kindly wait patiently. Once released, you will see
                      the released purchased token amount in your wallet.{" "}
                    </p>
                    <p>
                      You can revisit this page for an update on your
                      transaction by simply clicking the QUICK BUY Button in
                      ULITCOIN COM Homepage
                    </p>
                    <p>
                      The delivery of your purchase would be made and the
                      confirmation will appear in this page
                    </p>
                    <p>
                      In the event that you wish to contact ULIT, please email
                      us at myorder@ulitcoin.com
                    </p>
                    <Link to="/">
                      <button className={styles.quickBuy__btn}>
                        Go Back To Website
                      </button>
                    </Link>
                  </div>
                ) : (
                  <div className={styles.quickBuy__right__comfirm}>
                    <h3>Delivery Confirmation</h3>
                    <h4>
                      You have received your purchased token 25312.046 ULIT COIN
                    </h4>
                    <div className={styles.quickBuy__right__comfirm__main}>
                      <p>Payment details</p>
                      <div
                        className={styles.quickBuy__right__comfirm__main__item}
                      >
                        Payment Method <span>Coinbase Commerce</span>
                      </div>
                      <div
                        className={styles.quickBuy__right__comfirm__main__item}
                      >
                        Total amount <span>$10,000 (10,000 USDT)</span>
                      </div>

                      <div
                        className={styles.quickBuy__right__comfirm__main__item}
                      >
                        Time <span>15 Jan 2022, 21:16 PM</span>
                      </div>
                      <div
                        className={styles.quickBuy__right__comfirm__main__item}
                      >
                        Paid Invoice confirmation for <span>John Doe</span>
                      </div>
                      <div
                        className={styles.quickBuy__right__comfirm__main__item}
                      >
                        Invoice number <span>001</span>
                      </div>
                      <p>
                        Transaction ID <span>065468748942</span>
                      </p>
                    </div>

                    <div className={styles.quickBuy__right__comfirm__btnCon}>
                      <div
                        className={
                          styles.quickBuy__right__comfirm__btnCon__download
                        }
                      >
                        <img src={downloadIcon} alt="" /> Download Receipt PDF
                      </div>
                      <button
                        className={styles.quickBuy__btn}
                        // onClick={() => setStep()}
                      >
                        View Wallet
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}

            {step === 7 && (
              <div className="login__otp">
                <h2 className="login__otp__title">Biometrics Registration</h2>
                <p className="login__otp__easy">
                  <b>
                    REGISTER YOUR BIOMETRICS FOR FASTER LOGIN, DO IT NOW OR
                    LATER
                  </b>
                  <br />
                </p>
                <div
                  className="login__otp__biometric"
                  style={{ background: "#111114" }}
                >
                  <p style={{ textAlign: "center" }}>
                    Register with Biometrics: Touch ID and Face ID for enhanced
                    Login and Account Security. Enabling your Biometrics will
                    help you to unlock a better experience across Ultainfinity.
                  </p>
                  <div className="login__otp__biometric__main">
                    <div className="login__otp__biometric__item">
                      <img src={fingerPrintIcon} alt="" />
                      <span>Touch ID</span>
                    </div>
                    <div className="login__otp__biometric__item">
                      <img src={faceIdIcon} alt="" />
                      <span>Face ID</span>
                    </div>
                  </div>
                  <div className="getHelp__main">
                    {!biometricSupported ? (
                      <span className="login__biom__biometric__err">
                        Biometrics is not supported on this device
                      </span>
                    ) : biometricRegisterSuc ? (
                      <Button
                        width="250px"
                        $fill
                        onClick={() => {
                          setStep(1);
                          setBiometricRegisterSuc(false);
                        }}
                      >
                        Go Back
                      </Button>
                    ) : (
                      <ButtonMain
                        onClick={handleGenerateChallange}
                        $fill
                        width="280px"
                      >
                        {bioloading ? (
                          <ReactLoading
                            color={"white"}
                            width={20}
                            height={20}
                            type="spin"
                          />
                        ) : (
                          "Register Biometrics"
                        )}
                      </ButtonMain>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default QuickBuy;
