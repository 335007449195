import wallet1 from "../../assets/wallet1.png";
import wallet2 from "../../assets/wallet2.png";
import wallet3 from "../../assets/wallet3.png";
import wallet4 from "../../assets/wallet4.png";
// import wallet5 from "../../assets/wallet5.png";
import wallet6 from "../../assets/wallet6.png";
import wallet7 from "../../assets/wallet7.png";
import wallet9 from "../../assets/wallet9.png";
import wallet10 from "../../assets/wallet10.png";
// import wallet11 from "../../assets/wallet11.png";
import wallet12 from "../../assets/wallet12.png";
import wallet13 from "../../assets/wallet13.png";
import wallet14 from "../../assets/wallet14.png";
// import wallet15 from "../../assets/wallet15.png";
import wallet16 from "../../assets/wallet16.png";
import wallet17 from "../../assets/wallet17.png";
import wallet18 from "../../assets/wallet18.png";
import wallet19 from "../../assets/wallet19.png";
import wallet20 from "../../assets/wallet20.png";
import wallet21 from "../../assets/wallet21.png";
import wallet22 from "../../assets/wallet22.png";
import wallet23 from "../../assets/wallet23.png";
import wallet24 from "../../assets/wallet24.png";
import wallet from "../../assets/wallet.png";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
// import { ethers } from "ethers";
import styles from "./walletConnectMain.module.scss";
import { Button } from "../../component/StyledComponent";
import { useAccount, useConnect } from "wagmi";
import { getAddress } from "sats-connect";
import { getTrustWalletInjectedProvider } from "../../shared/utils/WalletProviders";
import axios from "axios";
import ReactLoading from "react-loading";
import { connect as argentConnect } from "@argent/get-starknet";
import { DeFiWeb3Connector } from "@deficonnect/web3-connector";
import backIcon from "../../assets/back.svg";

// const networks = {
//   polygon: {
//     chainId: `0x${Number(137).toString(16)}`,
//     chainName: "Polygon Mainnet",
//     nativeCurrency: {
//       name: "MATIC",
//       symbol: "MATIC",
//       decimals: 18,
//     },
//     rpcUrls: ["https://polygon-rpc.com/"],
//     blockExplorerUrls: ["https://polygonscan.com/"],
//   },
//   bsc: {
//     chainId: `0x${Number(56).toString(16)}`,
//     chainName: "Binance Smart Chain Mainnet",
//     nativeCurrency: {
//       name: "Binance Chain Native Token",
//       symbol: "BNB",
//       decimals: 18,
//     },
//     rpcUrls: [
//       "https://bsc-dataseed1.binance.org",
//       "https://bsc-dataseed2.binance.org",
//       "https://bsc-dataseed3.binance.org",
//       "https://bsc-dataseed4.binance.org",
//       "https://bsc-dataseed1.defibit.io",
//       "https://bsc-dataseed2.defibit.io",
//       "https://bsc-dataseed3.defibit.io",
//       "https://bsc-dataseed4.defibit.io",
//       "https://bsc-dataseed1.ninicoin.io",
//       "https://bsc-dataseed2.ninicoin.io",
//       "https://bsc-dataseed3.ninicoin.io",
//       "https://bsc-dataseed4.ninicoin.io",
//       "wss://bsc-ws-node.nariox.org",
//     ],
//     blockExplorerUrls: ["https://bscscan.com"],
//   },
// };

const injectedProvider = await getTrustWalletInjectedProvider();

const WalletConnectMain = ({ setStep, disconnect }) => {
  //   const [showNetwork, setShowNetwork] = useState(false);
  //   const [network, setNetwork] = useState("bsc");
  //   const [chainId, setChainId] = useState("");
  const [stage, setStage] = useState(1);
  const [data, setData] = useState({
    address: "",
    network: "",
    wallet: "Metamask",
    walletId: "",
    img: wallet1,
  });
  const [mainLoading, setMainLoading] = useState(false);
  const [preAddress, setPreAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const { connect, connectors } = useConnect();
  const { address, isConnected } = useAccount();
  const user = JSON.parse(sessionStorage.getItem("userUlta") ?? "{}").user;
  // const navigate = useNavigate();
  //   const changeNetwork = async ({ networkName }) => {
  //     try {
  //       if (!window.ethereum) throw new Error("No crypto wallet found");
  //       await window.ethereum.request({
  //         method: "wallet_addEthereumChain",
  //         params: [
  //           {
  //             ...networks[networkName],
  //           },
  //         ],
  //       });
  //     } catch (err) {
  //       toast.error("Connection Failed", {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //     }
  //   };

  //   const handleNetworkSwitch = async (networkName) => {
  //     await changeNetwork({ networkName });
  //   };

  // Button handler button for handling a
  // request event for metamask

  // console.log("injectedProvider", injectedProvider);

  // console.log("connector", connectors[0]);

  const trustConnect = async () => {
    if (injectedProvider) {
      try {
        const account = await injectedProvider.request({
          method: "eth_requestAccounts",
        });

        setData((curr) => {
          return { ...curr, address: account[0] };
        });

        saveWallet(account[0], "Binance Blockchain Network");
        // accountChangeHandler(account[0]);
        // console.log(account); // => ['0x...']
      } catch (e) {
        toast.error("Connection Failed", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      toast.error("Install Trust Wallet Extension", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const bitKeepConnect = () => {
    if (window.bitkeep && window.bitkeep.ethereum) {
      const BitKeepProvider = window.bitkeep && window.bitkeep.ethereum;

      BitKeepProvider.request({ method: "eth_requestAccounts" })
        .then((res) => {
          console.log("res", res);
          setData((curr) => {
            return { ...curr, address: res[0] };
          });

          saveWallet(res[0], "Bitcoin Blockchain Network");
          // accountChangeHandler(res[0]);
        })
        .catch((err) => {
          toast.error("Connection Failed", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } else {
      toast.error("Install Bitkeep Wallet Extension", {
        position: toast.POSITION.TOP_RIGHT,
      });
      window.open("https://bitkeep.com/en/download?type=2");
    }
  };

  const mathConnect = () => {
    if (window.ethereum && window.ethereum.isMathWallet) {
      // const mathProvider = window.ethereum && window.ethereum.isMathWallet;

      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((res) => {
          console.log("res", res);

          setData((curr) => {
            return { ...curr, address: res[0] };
          });

          saveWallet(res[0], "Binance Blockchain Network");
          // accountChangeHandler(res[0]);
        })
        .catch((err) => {
          toast.error("Connection Failed", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } else {
      toast.error("Install Math Wallet Extension", {
        position: toast.POSITION.TOP_RIGHT,
      });
      window.open("https://mathwallet.org/", "_blank");
    }
  };

  // console.log("window", window);
  const dalConect = async () => {
    const doge = window?.DogeApi;

    if (doge) {
      if (await doge.isEnabled()) {
        try {
          const { userAddress } = await doge.userAddress();
          // accountChangeHandler(userAddress);

          setData((curr) => {
            return { ...curr, address: userAddress };
          });

          saveWallet(userAddress, "Dogecoin Blockchain Network");
        } catch (error) {
          toast.error("Connection Failed", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }

        // const { network } = await doge.network();
      } else {
        const { status } = await doge.enable();
        if (status === "success") {
          try {
            const { userAddress } = await doge.userAddress();
            setData((curr) => {
              return { ...curr, address: userAddress };
            });

            saveWallet(userAddress, "Dogecoin Blockchain Network");
          } catch (error) {
            toast.error("Connection Failed", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      }
    } else {
      toast.error("Install DPAL Wallet Extension", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const unielonConect = async () => {
    const provider = window?.unielon;

    if (provider) {
      try {
        const res = await provider.requestAccounts();
        // accountChangeHandler(res[0]);
        setData((curr) => {
          return { ...curr, address: res[0] };
        });

        saveWallet(res[0], "Dogecoin Blockchain Network");
      } catch (error) {
        toast.error("Connection Failed", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      toast.error("Install Unielon Wallet Extension", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const litescribeConect = async () => {
    const provider = window?.litescribe;

    if (provider) {
      try {
        const res = await provider.requestAccounts();

        setData((curr) => {
          return { ...curr, address: res[0] };
        });

        saveWallet(res[0], "Litecoin Blockchain Network");
        // accountChangeHandler(res[0]);
      } catch (error) {
        toast.error("Connection Failed", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      toast.error("Install Litescribe Wallet Extension", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const unisatConect = async () => {
    const provider = window?.unisat;

    if (provider) {
      try {
        const res = await provider.requestAccounts();
        // accountChangeHandler(res[0]);
        setData((curr) => {
          return { ...curr, address: res[0] };
        });

        saveWallet(res[0], "Bitcoin Blockchain Network");
      } catch (error) {
        toast.error("Connection Failed", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      toast.error("Install Unisat Wallet Extension", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const binanceConect = async () => {
    if (window.BinanceChain) {
      // console.log("window.BinanceChain", window.BinanceChain.requestAccounts());
      try {
        const res = await window.BinanceChain.request({
          method: "eth_requestAccounts",
        });
        // accountChangeHandler(res[0]);
        setData((curr) => {
          return { ...curr, address: res[0] };
        });

        saveWallet(res[0], "Binance Blockchain Network");
      } catch (error) {
        toast.error("Connection Failed", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      toast.error("Install Binance Wallet Extension", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const exodusConect = async () => {
    if (window.ethereum) {
      // console.log("window.BinanceChain", window.BinanceChain.requestAccounts());
      try {
        const res = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        // accountChangeHandler(res[0]);
        setData((curr) => {
          return { ...curr, address: res[0] };
        });

        saveWallet(res[0], "Ethereum Blockchain Network");
      } catch (error) {
        toast.error("Connection Failed", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      toast.error("Install Exodus Wallet Extension", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const okxConect = async () => {
    if (window.okxwallet && window.okxwallet.isOkxWallet) {
      // console.log("window.BinanceChain", window.BinanceChain.requestAccounts());
      try {
        const res = await window.okxwallet.request({
          method: "eth_requestAccounts",
        });
        // accountChangeHandler(res[0]);
        setData((curr) => {
          return { ...curr, address: res[0] };
        });

        saveWallet(res[0], "Dogecoin Blockchain Network");
      } catch (error) {
        toast.error("Connection Failed", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      toast.error("Install OKX Wallet Extension", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const coin98Conect = async () => {
    if (window.ethereum && window.coin98) {
      // console.log("window.BinanceChain", window.BinanceChain.requestAccounts());
      try {
        const res = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        // accountChangeHandler(res[0]);
        setData((curr) => {
          return { ...curr, address: res[0] };
        });

        saveWallet(res[0], "Binance Blockchain Network");
      } catch (error) {
        toast.error("Connection Failed", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      toast.error("Install Coin98 Wallet Extension", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const xdefiConect = async () => {
    if (window.xfi && window.xfi.litecoin) {
      window.xfi.litecoin.request(
        { method: "request_accounts", params: [] },
        (error, accounts) => {
          setData((curr) => {
            return { ...curr, address: accounts[0] };
          });

          saveWallet(accounts[0], "Litecoin Blockchain Network");
        }
      );
    } else {
      toast.error("Install XDEFI Wallet Extension", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  //   const getbalance = (address) => {
  //     window.ethereum
  //       .request({
  //         method: "eth_getBalance",
  //         params: [address, "latest"],
  //       })
  //       .then((balance) => {
  //         setdata((curr) => {
  //           return { ...curr, Balance: ethers.formatEther(balance) };
  //         });
  //       })
  //       .catch((err) => {
  //         toast.error("Connection Failed", {
  //           position: toast.POSITION.TOP_RIGHT,
  //         });
  //       });
  //   };

  const getAddressOptions = {
    payload: {
      purposes: ["ordinals", "payment"],
      message: "Address for receiving Ordinals and payments",
      network: {
        type: "Mainnet",
      },
    },
    onFinish: (response) => {
      console.log(
        "response?.addresses?.[1]?.address",
        response?.addresses?.[1]?.address
      );
      setData((curr) => {
        return { ...curr, address: response?.addresses?.[1]?.address };
      });
      // toast.success("Wallet Connected Successfully", {
      //   position: toast.POSITION.TOP_RIGHT,
      // });

      // setStage(4);
      saveWallet(response?.addresses?.[1]?.address);
    },
    onCancel: () =>
      toast.error("Request canceled", {
        position: toast.POSITION.TOP_RIGHT,
      }),
  };

  const xverseConnect = async () => {
    try {
      await getAddress(getAddressOptions);
    } catch (error) {
      toast.error("Install Xverse Wallet", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const argentxConnect = async () => {
    try {
      const starknet = await argentConnect();
      await starknet?.enable({ starknetVersion: "v4" });

      console.log("starknet.selectedAddress", starknet);
      // accountChangeHandler(starknet.selectedAddress);
      setData((curr) => {
        return { ...curr, address: starknet.selectedAddress };
      });

      saveWallet(starknet.selectedAddress, "Ethereum Blockchain Network");
    } catch (error) {
      toast.error("Install Argent x Wallet", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // const connectorr = new DeFiWeb3Connector({
  //   supportedChainIds: [1],
  //   appName: "ULIT Coin",
  //   chainType: "eth", // only support 'eth' for DeFiWeb3Connector
  //   chainId: "25", // for eth is 1
  //   rpcUrls: {
  //     1: "https://mainnet.infura.io/v3/7c10d4fe703c4b218b5c2af42a9fb7cc",
  //     25: "https://evm-cronos.crypto.org/",
  //   },
  // });

  // console.log("first", connectorr);

  const cryptoConnect = async () => {
    const connector = new DeFiWeb3Connector({
      supportedChainIds: [1],
      appName: "ULIT Coin",
      chainType: "eth", // only support 'eth' for DeFiWeb3Connector
      chainId: "25", // for eth is 1
      rpcUrls: {
        1: "https://mainnet.infura.io/v3/7c10d4fe703c4b218b5c2af42a9fb7cc",
        25: "https://evm-cronos.crypto.org/",
      },
    });
    connector.activate();

    try {
      const res = await connector.activate();

      setData((curr) => {
        return { ...curr, address: res.account };
      });

      saveWallet(res.account, "Litecoin Blockchain Network");
    } catch (err) {
      toast.error("Connection Failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    // console.log(
    //   "connector?.provider?.accounts?.[0]",
    //   connector?.provider?.deficonnectProvider
    // );
    // accountChangeHandler(connector?.provider?.accounts?.[0]);
    // connector.provider.on("accountsChanged", (accounts) => {
    //   console.log(accounts);
    // });
  };

  const accountChangeHandler = (account) => {
    setData((curr) => {
      return { ...curr, address: account };
    });

    saveWallet(account);
    // toast.success("Wallet Connected Successfully", {
    //   position: toast.POSITION.TOP_RIGHT,
    // });

    // setStage(4);

    // getbalance(account);
  };

  const wallets = [
    {
      name: "Coinbase",
      icon: wallet2,
      connector: connectors[1],
    },
    {
      name: "Metamask",
      icon: wallet1,
      connector: connectors[0],
    },
    {
      name: "Exodus",
      icon: wallet3,
      connector: exodusConect,
    },
    {
      name: "Argent X Wallet",
      icon: wallet10,
      connector: argentxConnect,
    },
  ];
  const wallets2 = [
    {
      name: "Xverse",
      icon: wallet13,
      connector: xverseConnect,
    },
    {
      name: "UniSat Wallet",
      icon: wallet18,
      connector: unisatConect,
    },
    {
      name: "Bitkeep",
      icon: wallet12,
      connector: bitKeepConnect,
    },
    {
      name: "Ordinals Wallet (Web-Based)",
      icon: wallet19,
      connector: null,
    },
  ];
  const wallets3 = [
    {
      name: "TrustWallet",
      icon: wallet4,
      connector: trustConnect,
    },
    {
      name: "Binance",
      icon: wallet9,
      connector: binanceConect,
    },
    {
      name: "Math Wallet",
      icon: wallet7,
      connector: mathConnect,
    },
    {
      name: "Coin98 Wallet",
      icon: wallet6,
      connector: coin98Conect,
    },
  ];

  const wallets4 = [
    {
      name: "DPAL Wallet",
      icon: wallet16,
      connector: dalConect,
    },
    {
      name: "Unielon Wallet",
      icon: wallet14,
      connector: unielonConect,
    },
    {
      name: "OKX Wallet",
      icon: wallet17,
      connector: okxConect,
    },
    {
      name: "ULIT Wallet",
      icon: wallet20,
      connector: null,
    },
  ];

  const wallets5 = [
    {
      name: "Litescribe Wallet",
      icon: wallet21,
      connector: litescribeConect,
    },
    {
      name: "XDEFI Wallet",
      icon: wallet22,
      connector: xdefiConect,
    },
    {
      name: "Guarda Wallet",
      icon: wallet23,
      connector: null,
    },
    {
      name: "Crypto.com",
      icon: wallet24,
      connector: cryptoConnect,
    },
  ];

  //   useEffect(() => {
  //     if (data.address) {
  //       toast.success("Wallet Connected", {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //       setShowNetwork(true);
  //       // setStep(3);
  //     }
  //   }, [data.address]);

  //   const networkChanged = (chainId) => {
  //     setStep(3);
  //   };

  // useEffect(() => {
  //   window.ethereum.on("accountsChanged", (accounts) => {
  //     console.log(accounts);
  //   });

  //   return () => {
  //     window.ethereum.removeListener("chainChanged", (accounts) => {
  //       console.log(accounts);
  //     });
  //   };
  // }, []);

  // const handleConnect = () => {
  //   if (data.wallet === "Metamask") {
  //     bitKeepConnect();
  //   }
  // };
  // console.log("data", data);
  useEffect(() => {
    if (isConnected && address && stage === 2) {
      setData((curr) => {
        return { ...curr, address: address };
      });
      // toast.success("Wallet Connected Successfully", {
      //   position: toast.POSITION.TOP_RIGHT,
      // });

      // setStage(4);
      saveWallet(address);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected]);

  // console.log("user", user);

  const saveWallet = async (address, network) => {
    if (address) {
      const postData = {
        currency: "",
        address: address,
        network: network ? network : data.network,
      };

      setLoading(true);

      try {
        const response = await axios.post(
          process.env.REACT_APP_API_URL + `/launchpad/app/wallets`,
          postData,
          {
            headers: {
              Authorization: "Bearer " + user.accessToken,
              "user-id": user?.id,
            },
          }
        );
        setLoading(false);

        console.log("response", response);
        toast.success("Wallet Connected Successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });

        setStage(4);
      } catch (err) {
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message ||
          err.toString();
        setLoading(false);
        toast.error(message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };
  // const getWallet = async () => {
  //   setLoading(true);

  //   try {
  //     const response = await axios.get(
  //       process.env.REACT_APP_API_URL + `/launchpad/app/wallets/user-wallets`,
  //       {
  //         headers: {
  //           Authorization: "Bearer " + user.token,
  //           "user-id": user?.user?.id,
  //         },
  //       }
  //     );

  //     const latestWallet = response.data?.data?.length - 1;

  //     setLoading(false);

  //     setData((curr) => {
  //       return { ...curr, walletId: response.data?.data?.[latestWallet]?.id };
  //     });

  //     setStep(2);
  //   } catch (err) {
  //     const message =
  //       (err.response && err.response.data && err.response.data.message) ||
  //       err.message ||
  //       err.toString();
  //     setLoading(false);
  //     toast.error(message, {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //   }
  // };

  useEffect(() => {
    const initGetWallet = async () => {
      setMainLoading(true);

      try {
        const response = await axios.get(
          process.env.REACT_APP_API_URL + `/launchpad/app/wallets/user-wallets`,
          {
            headers: {
              Authorization: "Bearer " + user.accessToken,
              "user-id": user?.id,
            },
          }
        );
        setMainLoading(false);

        console.log("response", response);

        if (response.data?.data?.length > 0) {
          const latestWallet = response.data?.data?.length - 1;

          setData((curr) => {
            return {
              ...curr,
              walletId: response.data?.data?.[latestWallet]?.id,
              address: response.data?.data?.[latestWallet]?.wallet_address,
              network: response.data?.data?.[latestWallet]?.network,
            };
          });

          setStage(4);
        }
      } catch (err) {
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message ||
          err.toString();
        setMainLoading(false);
        toast.error(message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    };

    if (!disconnect) {
      initGetWallet();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleProceed = () => {
    setStage(1);
  };

  return (
    <div className={styles.walletConnectMain}>
      {mainLoading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ReactLoading color="white" width={50} height={50} type="spin" />
        </div>
      ) : (
        <>
          {stage >= 2 && stage !== 4 && (
            <div style={{ width: "70%", cursor: "pointer", marginBottom: 20 }}>
              <img onClick={() => setStage(stage - 1)} src={backIcon} alt="" />
            </div>
          )}
          {stage === 1 && (
            <div className={styles.walletConnectMain__wallet}>
              <h2>Connect To Wallet</h2>

              <h3>Ethereum Blockchain Network</h3>
              <div className={styles.walletConnectMain__wallet__disc}>
                <p>Do you want ULIT cryptocurrencies, as ERC20 Token Assets?</p>
                <p>
                  Choose the Ethereum Blockchain Network and use the recommended
                  Wallets
                </p>
              </div>
              <div className={styles.walletConnectMain__wallet__list}>
                {wallets.map((wallet, i) => {
                  return (
                    <div
                      onClick={() => {
                        setData((curr) => {
                          return {
                            ...curr,
                            wallet: wallet.name,
                            img: wallet.icon,
                            network: "Ethereum Blockchain Network",
                            connect: wallet.connector,
                          };
                        });
                        setStage(2);
                      }}
                      key={i}
                      className={
                        wallet.name === data.wallet
                          ? `${styles.walletConnectMain__wallet__list__item}`
                          : styles.walletConnectMain__wallet__list__item
                      }
                    >
                      <img src={wallet.icon} alt="" /> {wallet.name}
                    </div>
                  );
                })}
                {/* <div
    onClick={() => {
      setCurrWallet(wallet.name);
      setCurrWalletImg(wallet.icon);
      // wallet.click();
      setStage(2)
    }}
    className={styles.walletConnectMain__wallet__list__item}
  >
    <img src={wallet1} alt="" /> Metamask
  </div> */}
              </div>
              <h3>Bitcoin Blockchain Network</h3>
              <div className={styles.walletConnectMain__wallet__disc}>
                <p>Do you want ULIT cryptocurrencies, as BRC20 Token Assets?</p>
                <p>
                  Choose the Bitcoin Blockchain Network and use the recommended
                  Wallets
                </p>
              </div>
              <div className={styles.walletConnectMain__wallet__list}>
                {wallets2.map((wallet, i) => {
                  return (
                    <div
                      onClick={() => {
                        setData((curr) => {
                          return {
                            ...curr,
                            wallet: wallet.name,
                            img: wallet.icon,
                            network: "Bitcoin Blockchain Network",
                            connect: wallet.connector,
                          };
                        });
                        // wallet.click();
                        setStage(2);
                      }}
                      key={i}
                      className={
                        wallet.name === data.wallet
                          ? `${styles.walletConnectMain__wallet__list__item}`
                          : styles.walletConnectMain__wallet__list__item
                      }
                    >
                      <img src={wallet.icon} alt="" /> {wallet.name}
                    </div>
                  );
                })}
              </div>
              <h3>Binance Blockchain Network</h3>
              <div className={styles.walletConnectMain__wallet__disc}>
                <p>Do you want ULIT cryptocurrencies, as BEP20 Token Asset?</p>
                <p>
                  Choose the Binance Blockchain Network and use the recommended
                  Wallets
                </p>
              </div>
              <div className={styles.walletConnectMain__wallet__list}>
                {wallets3.map((wallet, i) => {
                  return (
                    <div
                      onClick={() => {
                        setData((curr) => {
                          return {
                            ...curr,
                            wallet: wallet.name,
                            img: wallet.icon,
                            network: "Binance Blockchain Network",
                            connect: wallet.connector,
                          };
                        });
                        setStage(2);
                      }}
                      key={i}
                      className={
                        wallet.name === data.wallet
                          ? `${styles.walletConnectMain__wallet__list__item}`
                          : styles.walletConnectMain__wallet__list__item
                      }
                    >
                      <img src={wallet.icon} alt="" /> {wallet.name}
                    </div>
                  );
                })}
              </div>
              <h3>Dogecoin Blockchain Network</h3>
              <div className={styles.walletConnectMain__wallet__disc}>
                <p>Do you want ULIT cryptocurrencies, as DRC20 Token Assets?</p>
                <p>
                  Choose the Dogecoin Blockchain Network and use the recommended
                  wallets
                </p>
              </div>
              <div className={styles.walletConnectMain__wallet__list}>
                {wallets4.map((wallet, i) => {
                  return (
                    <div
                      onClick={() => {
                        setData((curr) => {
                          return {
                            ...curr,
                            wallet: wallet.name,
                            img: wallet.icon,
                            network: "Dogecoin Blockchain Network",
                            connect: wallet.connector,
                          };
                        });
                        setStage(2);
                      }}
                      key={i}
                      className={
                        wallet.name === data.wallet
                          ? `${styles.walletConnectMain__wallet__list__item}`
                          : styles.walletConnectMain__wallet__list__item
                      }
                    >
                      <img src={wallet.icon} alt="" /> {wallet.name}
                    </div>
                  );
                })}
              </div>
              <h3>Litecoin Blockchain Network</h3>
              <div className={styles.walletConnectMain__wallet__disc}>
                <p>Do you want ULIT cryptocurrencies, as LTC20 Token Assets?</p>
                <p>
                  Choose the Litecoin Blockchain Network and use the recommended
                  wallets
                </p>
              </div>
              <div className={styles.walletConnectMain__wallet__list}>
                {wallets5.map((wallet, i) => {
                  return (
                    <div
                      onClick={() => {
                        setData((curr) => {
                          return {
                            ...curr,
                            wallet: wallet.name,
                            img: wallet.icon,
                            network: "Litecoin Blockchain Network",
                            connect: wallet.connector,
                          };
                        });
                        setStage(2);
                      }}
                      key={i}
                      className={
                        wallet.name === data.wallet
                          ? `${styles.walletConnectMain__wallet__list__item}`
                          : styles.walletConnectMain__wallet__list__item
                      }
                    >
                      <img src={wallet.icon} alt="" /> {wallet.name}
                    </div>
                  );
                })}
              </div>

              <div className={styles.walletConnectMain__wallet__instr}>
                We recommended that you download, possess a compatible wallet to
                receive directly from us the selected version of the
                cryptocurrency that you are purchasing, and or connect to your
                ULITCOIN.COM dashboard wallet a compatible crypto wallet to
                receive the selected version of cryptocurrencies that you
                purchased
              </div>

              {/* <div> {data.address}</div>
  <div> {data.Balance}</div> */}

              {/* <button className={styles.walletConnectMain__btn} onClick={}>Connect to wallet</button> */}
              {/* {!showNetwork && (
          <button
            onClick={() => bitKeepConnect()}
            className={`${styles.walletConnectMain__btn} ${styles.walletConnectMain__btn}`}
          >
            Connect Wallet
          </button>
        )} */}

              {/* {showNetwork && (
  <div className={styles.walletConnectMain__net}>
    <h3>Select Available Network</h3>
    <select
      onChange={(e) => setNetwork(e.target.value)}
      name="Network"
      id="Network"
    >
      <option value="bsc">BNB Smart Chain Mainnet </option>
      <option value="polygon">Polygon Mainnet </option>
    </select>
  
    <button className={styles.walletConnectMain__btn} onClick={() => handleNetworkSwitch(network)}>
      Switch Network
    </button>
  </div>
  )} */}
            </div>
          )}

          {stage === 2 && (
            <div className={styles.walletConnectMain__wallet}>
              <h2>Select An Option</h2>

              {data.connect !== null && (
                <div className={styles.walletConnectMain__wallet__option}>
                  <div
                    className={styles.walletConnectMain__wallet__option__right}
                  >
                    <h4>Connect Automatically </h4>
                    <p>
                      Connect automatically if you have a mobile or web-based or
                      extension wallet.
                    </p>
                  </div>

                  {data.wallet === "Coinbase" || data.wallet === "Metamask" ? (
                    <Button
                      style={{
                        background:
                          "linear-gradient(315deg, #9475D4 0%, #30F 100%)",
                      }}
                      disabled={!data.connect.ready}
                      radius="5px"
                      $fill
                      onClick={() => {
                        try {
                          connect({ connector: data.connect });
                          // setAddWallet(!addWallet);
                        } catch (error) {
                          console.log(error.message);
                          console.log(error);
                          toast.success(error.message, {
                            position: toast.POSITION.TOP_RIGHT,
                          });
                        }
                      }}
                    >
                      {loading ? (
                        <ReactLoading
                          color="white"
                          width={30}
                          height={30}
                          type="spin"
                        />
                      ) : (
                        "Proceed"
                      )}
                    </Button>
                  ) : (
                    <Button
                      style={{
                        background:
                          "linear-gradient(315deg, #9475D4 0%, #30F 100%)",
                      }}
                      radius="5px"
                      $fill
                      onClick={() => {
                        try {
                          data.connect();
                        } catch (error) {
                          console.log(error.message);
                          console.log(error);
                          toast.success(error.message, {
                            position: toast.POSITION.TOP_RIGHT,
                          });
                        }
                        // setAddWallet(!addWallet);
                      }}
                    >
                      {loading ? (
                        <ReactLoading
                          color="white"
                          width={30}
                          height={30}
                          type="spin"
                        />
                      ) : (
                        "Proceed"
                      )}
                    </Button>
                  )}
                </div>
              )}
              <div className={styles.walletConnectMain__wallet__option}>
                <div
                  className={styles.walletConnectMain__wallet__option__right}
                >
                  <h4>Connect Manually</h4>
                  <p>Connect to your wallet manually now.</p>
                </div>

                <Button
                  style={{
                    background:
                      "linear-gradient(315deg, #9475D4 0%, #30F 100%)",
                  }}
                  radius="5px"
                  $fill
                  onClick={() => setStage(3)}
                >
                  Proceed
                </Button>
              </div>
            </div>
          )}

          {stage === 3 && (
            <div className={styles.walletConnectMain__connect}>
              {/* <h2>Connect To Wallet</h2> */}
              {data.network === "Dogecoin Blockchain Network" ? (
                <div className={styles.walletConnectMain__connect__inputCon}>
                  <label htmlFor="asset">Select Asset Type </label>
                  <select name="asset" id="asset">
                    <option value="FULC">FULC</option>
                    <option value="DULC">DULC</option>
                    <option value="TXLA">TXLA</option>
                    <option value="300M">300M</option>
                    <option value="MegaT">MegaT</option>
                  </select>
                </div>
              ) : (
                <div className={styles.walletConnectMain__connect__inputCon}>
                  <label htmlFor="asset">Select Asset Type </label>
                  <input
                    disabled
                    value="ULC"
                    type="text"
                    name="asset"
                    id="asset"
                  />
                </div>
              )}
              <div className={styles.walletConnectMain__connect__inputCon}>
                <label htmlFor="network">Blockchain Network </label>
                <input
                  disabled
                  value={data.network}
                  type="text"
                  name="network"
                  id="network"
                />
              </div>
              <div
                style={{ marginBottom: 60 }}
                className={styles.walletConnectMain__connect__inputCon}
              >
                <label htmlFor="walletAddress">
                  Enter your wallet address{" "}
                </label>
                <input
                  onChange={(e) => setPreAddress(e.target.value)}
                  type="text"
                  name="walletAddress"
                  id="walletAddress"
                />
              </div>

              <button
                className={styles.walletConnectMain__btn}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {
                  // setData((curr) => {
                  //   return { ...curr, address: preAddress };
                  // });
                  accountChangeHandler(preAddress);
                  // setAddWallet(!addWallet);
                }}
              >
                {loading ? (
                  <ReactLoading
                    color="white"
                    width={30}
                    height={30}
                    type="spin"
                  />
                ) : (
                  "Next"
                )}
              </button>
            </div>
          )}

          {stage === 4 && (
            <div className={styles.walletConnectMain__connected}>
              <h2>Wallet Connected Successfully</h2>
              <img
                style={{ width: 138, marginBottom: 20 }}
                src={wallet}
                alt=""
              />
              <img
                style={{ width: "45px", marginBottom: 10 }}
                src={data.img}
                alt=""
              />
              <h4>{data.wallet}</h4>
              <p>Wallet Address </p>
              <div className={styles.walletConnectMain__connected__address}>
                {data.address?.substring(0, 20)}*****
              </div>

              {/* <button className={styles.walletConnectMain__btn} onClick={() => setStep(2)}> */}
              <button
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className={styles.walletConnectMain__btnAlt}
                onClick={handleProceed}
              >
                {/* {loading ? (
                  <ReactLoading
                    color="white"
                    width={30}
                    height={30}
                    type="spin"
                  />
                ) : (
                  "Proceed"
                )} */}
                Disconnect Wallet
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default WalletConnectMain;
